<section id="content_wrap" class="nbf_tpl_pagesection_vertical_norwd content_wrap contentwidth">
  <div class="maincnt">

    <div class="terms-wrapper">
      <div class="terms-top">
        <div class="terms-center lf-bg">
          <div class="terms-title">Cookie Policy</div>
          <div class="trms-notice">PLEASE READ BEFORE YOU BOOK</div>
        </div>
      </div>

      <div class="terms-second">
        <div class="terms-center">
          <div class="terms-intro">Cookie Policy</div>
          <div class="term-para">
            <p>When you use our website we use cookies for various purposes like analyzing the traffic, advertising, and more.  </p>
          </div>
        </div>
      </div>

      <div class="terms-accordion">
        <div class="terms-center">
          <div class="acc-mainn">
            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(1)"> What are cookies?   </div>
              <div class="acc-cont" *ngIf="showTab == 1 && activeStatus == 1">
                <div class="bwm">
                  Cookies are small files that are placed in your system when you browse our website. These files help us to understand what type of content you consume. This further helps us to determine your preferences and how you use a service or website.
                </div>
                <div>There are two kinds of cookies:</div>
                <div class="sml-lists">
                  <ul>
                    <li>First Party Cookies: Cookies that are placed directly by us.</li>
                    <li>Third-Party Cookies: Cookies that are placed by third-party vendors under our approval.</li>
                  </ul>
                </div>
                <div class="bwm2">Apart from this, there are also session and permanent cookies.</div>
                <div class="sml-lists">
                  <ul>
                    <li>Session Cookies are deleted as soon as your browsing session is over.</li>
                    <li>Permanent Cookies have a different range of lifespan depending on their requirement and nature.</li>
                  </ul>
                </div>
                <div class="term-para">
                  <p>
                    Your information like browser type, content you browsed, etc are saved with us so that we can understand the user journey and preferences.
                    There are tracking technologies in place too that include web beacons, scripts, tracking URL's or software development kits.
                  </p>
                  <p>
                    All these things help to understand how the application or website is used. Your unique identifiers are recorded like device ID, IP address, in-app activity,
                    and network location for a better browsing experience.How are cookies used?
                  </p>

                </div>
              </div>
            </div>
            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(2)">How are cookies used?  </div>
              <div class="acc-cont" *ngIf="showTab == 2 && activeStatus == 2">
                <div class="bwm">
                  Cookies are used to collect the following information: IP address, device ID, viewed pages, browser type, browsing information, operating system, internet service provider, timestamp, response to an advertisement, URL, features used, and more.
                </div>
                <div>These factors allow recognizing you as a user and thus offering you better access to the website.</div>
                <div>When it comes to purpose, cookies can be divided into three categories:</div>

                <div class="sml-lists">
                  <ul>
                    <li>Functional Cookies</li>

                  </ul>
                </div>
                <div class="bwm2">This kind of cookie helps to make your browsing and booking quick and easy by reading your preferences. It also enables security and accessibility features. Functional cookies help the website to work properly so you can sign in and browse easily. These cookies remember your choices like language and more. It also allows you to store your login credentials so that you can skip the hassle. However, be assured that the passwords are always encrypted.</div>
                <div class="sml-lists">
                  <ul>
                    <li>Analytical Cookies</li>
                  </ul>
                </div>
                <div class="term-para">
                  <p>
                    These cookies help to analyze that as how our websites and apps are used. For example, how customers use our website, checks the relevancy, what works and what doesn't, effectiveness of ads, and more. The data we get helps us to analyze different things and your experience of using our website. We can get the information of the number of clicks, pages you visited, and more by using these cookies.
                  </p>
                </div>
                <div class="sml-lists">
                  <ul>
                    <li>Marketing Cookies</li>
                  </ul>
                </div>
                <div class="term-para">
                  <p>
                    These cookies help to tell us that what kind of ads we should display for a particular user on our website and apps. We can help to identify your interests so that we can offer you personalized content that resonates with your likings. Based on your browsing activities we offer you promotional content for example destinations you wish to visit or accommodations you want to stay at. These are called retargeting ads and can be shown even after your session at our website has ended. Apart from this, the social media sharing buttons pick your data too.
                  </p>
                  <p>Marketing cookies help us to understand your likes and based on that we send content your way. To know more about the privacy policy of third-party providers like social media sites, please read their specific policy in detail.</p>
                  <p>
                    We work with third-party vendors and thus we can share your information like an email address or phone number. This is done so that they can create ads based on the customer persona.

                  </p>
                </div>
              </div>
            </div>
            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(3)">What are your choices?                </div>
              <div class="acc-cont" *ngIf="showTab == 3 && activeStatus == 3">
                <div class="bwm">
                  You can delete or manage your cookies by going to the settings of your browser. By opening the settings you can select that what types of cookies you want to select or reject.

                </div>
                <div class="bwn">
                  Please note that if you block some important cookies then you might not be able to use some of our features. You can block cookies like analytical and advertising cookies. Also, even if you have opted out of certain advertising or content, you may still see the matter. Opting out just means that the network you have opted out from will stop showing you ads based on your preferences.
                </div>
              </div>
            </div>
            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(4)">
                How to contact us?

              </div>
              <div class="acc-cont" *ngIf="showTab == 4 && activeStatus == 4">
                <div class="bwm">
                  If you have any queries or concerns regarding this then you can write to us at (provide email address)We can update our cookies policy from time to time. Thus, make sure that you read it regularly to be aware of any changes. The moment you start using our website will mark as an acceptance from your end to all the policies.


                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


  </div>
</section>
