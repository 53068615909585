import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { CompanySettingsService } from '../services/company-settings.service';

@Component({
  selector: 'app-inner-header',
  templateUrl: './inner-header.component.html',
  styleUrls: ['./inner-header.component.css']
})
export class InnerHeaderComponent implements OnInit {

  IsTest: boolean = true;
  isShown: boolean = false;
  callText: string = '';
  rediectLink: string = '';
  callTel: string = '';
  languageCode: string = '';
  countryCode: string = '';
  countryCodes: any[] = ['us', 'gb'];
  dynamicUrl: string = '';
  BrandId: number = 0;
  IsMOSFilter: boolean = false;
  @Input() brandid: any;
  agentbrandid: any;
  agentBrandName: string = '';
  agentBrandImage: string = '';
  CompanySettings = CompanySettingsService;
  constructor(private _companySetting: CompanySettingsService, private commonService: CommonService) {
    this.IsTest = true;
    this.isShown = false;
    this.IsMOSFilter = this.commonService.isFromMOSSellingProject();
    //this.IsMOSFilter = _companySetting.isFromMOSProject();
    this.dynamicUrl = window.location.pathname.split('/')[1];
    this.languageCode = this.dynamicUrl.split('-')[0];
    this.countryCode = this.dynamicUrl.split('-')[1];
    if (this.languageCode == 'en' && this.countryCodes.includes(this.countryCode)) {
      if (this.countryCode == 'gb') {
        this.BrandId = 16;
        this.callText = "0203 515 0876";
        this.callTel = "tel:02035150876";       
      }
      else {
        this.BrandId = 17;
        this.callText = "1866 389 9767";
        this.callTel = "tel:18663899767";
      }
    }
    this._companySetting.GetConfiguration(this.languageCode, this.countryCode).subscribe(responseItems => {
      var configurationResponse = responseItems as any;
      if (configurationResponse.languageCode == 'en' && this.countryCodes.includes(configurationResponse.countryCode)) {
        if (configurationResponse.countryCode == 'gb') {
          this.BrandId = 16;
          this.callText = "0203 515 0876";
          this.callTel = "tel:02035150876";    
        }
        else {
          this.BrandId = 17;
          this.callText = "1866 389 9767";
          this.callTel = "tel:18663899767";
        }

      }
      this.CompanySettings.Brand_ID = this.BrandId;
      this.CompanySettings.CurrencySymbol = configurationResponse.currencySymbol;
      this.CompanySettings.Currency = configurationResponse.currency;
      this.CompanySettings.CultureCode = configurationResponse.cultureCode;
      this.CompanySettings.MetaChannel = configurationResponse.metaChannel;
      this.languageCode = configurationResponse.languageCode;
      this.countryCode = configurationResponse.countryCode;
      if (this.IsMOSFilter == true) {
        this.agentbrandid = this.brandid;
        let agentDetails = _companySetting.BrandDetail(this.agentbrandid);
        if (agentDetails != null) {
          if (agentDetails.length == 1) {
            this.agentBrandName = agentDetails[0].brandName;
            this.agentBrandImage = agentDetails[0].brandImageUrl;
          }
        }
      }
    });
  }
  ngOnChanges() {
    if (this.IsMOSFilter == true) {
      this.agentbrandid = this.brandid;
      let agentDetails = this._companySetting.BrandDetail(this.agentbrandid);
      if (agentDetails != null) {
        if (agentDetails.length == 1) {
          this.agentBrandName = agentDetails[0].brandName;
          this.agentBrandImage = agentDetails[0].brandImageUrl;
        }
      }
    }
  }
  ngOnInit(): void {
    this.IsTest = true;
  }
  RedirectToHomePage() {
    this.isShown = true;
  }
}
