import { Component } from '@angular/core';
import { environment } from '../../environments/environment';
import { CommonService } from '../services/common.service';
import { CompanySettingsService } from '../services/company-settings.service';

@Component({
  selector: 'app-other-header',
  templateUrl: './other-header.component.html',
  styleUrls: ['./other-header.component.css']
})
export class OtherHeaderComponent {
  callText: string = '';
  callTel: string = '';
  languageCode: string = '';
  countryCode: string = '';
  countryCodes: any[] = ['us', 'gb'];
  dynamicUrl: string = '';
  BrandId: number = 0;
  IsMOSFilter: boolean = false;
  IsOpenCurrencyPopup: boolean = false;
  CurrencySelectedValue: string = "United States";
  CurrencyValue: string = "USD $";
  CompanySettings = CompanySettingsService;
  constructor(private _companySetting: CompanySettingsService, private commonService: CommonService) {
    this.dynamicUrl = window.location.pathname.split('/')[1];
    this.languageCode = this.dynamicUrl.split('-')[0];
    this.countryCode = this.dynamicUrl.split('-')[1];
    if (this.languageCode == 'en' && this.countryCodes.includes(this.countryCode)) {
      if (this.countryCode == 'gb') {
        this.BrandId = 16;
        this.callText = "0203 515 0876";
        this.callTel = "tel:02035150876";
      }
      else {
        this.BrandId = 17;
        this.callText = "1866 389 9767";
        this.callTel = "tel:18663899767";
     
      }
    }
    this._companySetting.GetConfiguration(this.languageCode, this.countryCode).subscribe(responseItems => {
      var configurationResponse = responseItems as any;
      if (configurationResponse.languageCode == 'en' && this.countryCodes.includes(configurationResponse.countryCode)) {
        if (configurationResponse.countryCode == 'gb') {
          this.BrandId = 16;
          this.callText = "0203 515 0876";
          this.callTel = "tel:02035150876";        
        }
        else {
          this.BrandId = 17;
          this.callText = "1866 389 9767";
          this.callTel = "tel:18663899767";
        }

      }
      this.CompanySettings.Brand_ID = this.BrandId;
      this.CompanySettings.CurrencySymbol = configurationResponse.currencySymbol;
      this.CompanySettings.Currency = configurationResponse.currency;
      this.CompanySettings.CultureCode = configurationResponse.cultureCode;
      this.CompanySettings.MetaChannel = configurationResponse.metaChannel;
      this.languageCode = configurationResponse.languageCode;
      this.countryCode = configurationResponse.countryCode;
    });
    this.IsMOSFilter = this.commonService.isFromMOSSellingProject();
  }
  ChangeCurrency(event: any) {
    if (event.target.value == "United States") {
      this.CurrencyValue = "USD $";
      this.CurrencySelectedValue = "United States";
    }
    else {
      this.CurrencyValue = "GBP £";
      this.CurrencySelectedValue = "United Kingdom";
    }
  }
  SelectedRegion(event: any) {
    if (event == this.CurrencySelectedValue)
      return true;
    else return false;
  }
  CurrencySave() {
    let url = "";
    this.IsOpenCurrencyPopup = false;
    if (this.CurrencySelectedValue == "United States") {
      //  this.CurrencyValue = "USD $";
      if (this.countryCode != 'us')
        url = environment.BaseURL + "en-us/hotel";
    }
    else {
      if (this.countryCode != 'gb')
        url = environment.BaseURL + "en-gb/hotel";
    }
    window.open(url, "_self");
  }
}
