import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutusComponent } from './aboutus/aboutus.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { CookiesPolicyComponent } from './cookies-policy/cookies-policy.component';
import { FaqsComponent } from './faqs/faqs.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CustomerSupportComponent } from './customer-support/customer-support.component';
import { CompanySettingsService } from './services/company-settings.service';

const routes: Routes = [
  { path: '', redirectTo: '/hotel', pathMatch: 'full' },
  { path: 'en-us', redirectTo: '/hotel', pathMatch: 'full' },
  { path: 'en-gb', redirectTo: '/hotel', pathMatch: 'full' },

  { path: 'aboutus', component: AboutusComponent },
  { path: 'en-us/aboutus', component: AboutusComponent},
  { path: 'en-gb/aboutus', component: AboutusComponent },

  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'en-us/privacy-policy', component: PrivacyPolicyComponent },
  { path: 'en-gb/privacy-policy', component: PrivacyPolicyComponent },

  { path: 'terms-conditions', component: TermsConditionsComponent },
  { path: 'en-us/terms-conditions', component: TermsConditionsComponent },
  { path: 'en-gb/terms-conditions', component: TermsConditionsComponent },

  { path: 'cookies-policy', component: CookiesPolicyComponent },
  { path: 'en-us/cookies-policy', component: CookiesPolicyComponent },
  { path: 'en-gb/cookies-policy', component: CookiesPolicyComponent },

  { path: 'customer-support', component: CustomerSupportComponent },
  { path: 'en-us/customer-support', component: CustomerSupportComponent },
  { path: 'en-gb/customer-support', component: CustomerSupportComponent },

  { path: 'faqs', component: FaqsComponent },
  { path: 'en-us/faqs', component: FaqsComponent },
  { path: 'en-gb/faqs', component: FaqsComponent },

  { path: 'hotel', loadChildren: () => import('./hotel/hotel.module').then(m => m.HotelModule) },
  { path: 'en-us/hotel', loadChildren: () => import('./hotel/hotel.module').then(m => m.HotelModule) },
  { path: 'en-gb/hotel', loadChildren: () => import('./hotel/hotel.module').then(m => m.HotelModule) },

  //{ path: 'myaccount', loadChildren: () => import('./my-account/my-account.module').then(m => m.MyAccountModule),canActivate: [RouteGuardService]  },
  { path: '**', component: PageNotFoundComponent, pathMatch: 'full' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  languageCode: string = "";
  countryCode: string = "";
  countryCodes: any[] = ['us', 'gb'];
  urlLanguageCode: string = "";
  urlCountryCode: string = "";
  brandId: number = 0;
  constructor(private _companySetting: CompanySettingsService) {
    var compareUrl = window.location.pathname.split(' / ')[0].toString();
    var urlPath = window.location.pathname.split(' / ')[0].toString().toLowerCase();
    console.log(" window.location path " + String(window.location));
    try {
      if (urlPath != '/') {
        var getBrandId = localStorage.getItem('BrandId');
        if (getBrandId != null)
          this.brandId = Number(getBrandId);
        this.urlLanguageCode = urlPath.toString().split('/')[1].split('-')[0].toLowerCase();
        this.urlCountryCode = urlPath.toString().split('/')[1].split('-')[1].toLowerCase();
        var getLanguageCode = localStorage.getItem(this.brandId + '_LanguageCode');
        var getCountryCode = localStorage.getItem(this.brandId + '_CountryCode');
        if (this.urlLanguageCode != getLanguageCode || this.urlCountryCode != getCountryCode) {
          localStorage.removeItem('BrandId');
          localStorage.removeItem(this.brandId + '_UrlLanguageCode');
          localStorage.removeItem(this.brandId + '_UrlCountryCode');
          localStorage.removeItem(this.brandId + '_LanguageCode');
          localStorage.removeItem(this.brandId + '_CountryCode');
          this._companySetting.GetConfiguration(this.urlLanguageCode, this.urlCountryCode).subscribe(responseItems => {
            var configurationResponse = responseItems as any;
            if (configurationResponse.languageCode == "en" && this.countryCodes.includes(configurationResponse.countryCode)) {
              if (configurationResponse.countryCode == 'gb')
                this.brandId = 16;
              else
                this.brandId = 17;
            }
            localStorage.setItem('BrandId', this.brandId.toString());
            var getBrandId = localStorage.getItem('BrandId');
            localStorage.setItem(this.brandId + '_LanguageCode', configurationResponse.languageCode);
            getLanguageCode = localStorage.getItem(this.brandId + '_LanguageCode');
            localStorage.setItem(this.brandId + '_CountryCode', configurationResponse.countryCode);
            getCountryCode = localStorage.getItem(this.brandId + '_CountryCode');
            localStorage.setItem(this.brandId + '_UrlLanguageCode', urlPath.toString().split('/')[1].split('-')[0].toLowerCase());
            localStorage.setItem(this.brandId + '_UrlCountryCode', urlPath.toString().split('/')[1].split('-')[1].toLowerCase());
            var urlLanguageCode = localStorage.getItem(this.brandId + '_UrlLanguageCode');
            var urlCountryCode = localStorage.getItem(this.brandId + '_UrlCountryCode');

            localStorage.removeItem(this.brandId + '_LastLanguageCode');
            localStorage.removeItem(this.brandId + '_LastCountryCode');
            localStorage.setItem(this.brandId + '_LastLanguageCode', this.urlLanguageCode);
            localStorage.setItem(this.brandId + '_LastCountryCode', this.urlCountryCode);
            var getLastLanguageCode = localStorage.getItem(this.brandId + '_LastLanguageCode');
            var getLastCountryCode = localStorage.getItem(this.brandId + '_LastCountryCode');
            localStorage.removeItem(this.brandId + '_UrlPath');
            localStorage.setItem(this.brandId + '_UrlPath', urlPath);
            var lastUrlPath = localStorage.getItem(this.brandId + '_UrlPath');
          });

        }
        else {
          if (this.urlLanguageCode == null && getLanguageCode != null)
            this.languageCode = getLanguageCode;
          else if (this.urlLanguageCode != null)
            this.languageCode = this.urlLanguageCode;
          if (this.urlCountryCode == null && getCountryCode != null)
            this.countryCode = getCountryCode;
          else if (this.urlCountryCode != null)
            this.countryCode = this.urlCountryCode;
          localStorage.removeItem(this.brandId + '_LanguageCode');
          localStorage.removeItem(this.brandId + '_CountryCode');

          localStorage.removeItem(this.brandId + '_UrlLanguageCode');
          localStorage.removeItem(this.brandId + '_UrlCountryCode');
          this._companySetting.GetConfiguration(this.languageCode, this.countryCode).subscribe(responseItems => {
            var configurationResponse = responseItems as any;
            localStorage.removeItem('BrandId');
            if (configurationResponse.languageCode == "en" && this.countryCodes.includes(configurationResponse.countryCode)) {
              if (configurationResponse.countryCode == 'gb')
                this.brandId = 16;
              else
                this.brandId = 17;
            }
            localStorage.setItem('BrandId', this.brandId.toString());
            var getBrandId = localStorage.getItem('BrandId');
            localStorage.setItem(this.brandId + '_LanguageCode', configurationResponse.languageCode);
            getLanguageCode = localStorage.getItem(this.brandId + '_LanguageCode');
            localStorage.setItem(this.brandId + '_CountryCode', configurationResponse.countryCode);
            getCountryCode = localStorage.getItem(this.brandId + '_CountryCode');

            localStorage.setItem(this.brandId + '_UrlLanguageCode', urlPath.toString().split('/')[1].split('-')[0].toLowerCase());
            localStorage.setItem(this.brandId + '_UrlCountryCode', urlPath.toString().split('/')[1].split('-')[1].toLowerCase());
            var urlLanguageCode = localStorage.getItem(this.brandId + '_UrlLanguageCode');
            var urlCountryCode = localStorage.getItem(this.brandId + '_UrlCountryCode');

            if (urlPath != compareUrl)
              window.location.pathname = urlPath;

          });
        }

      }
      else {
        var getBrandId = localStorage.getItem('BrandId');
        if (getBrandId != null)
          this.brandId = Number(getBrandId);
        if (localStorage.getItem(this.brandId + '_UrlPath') != null) {
          var getLanguageCode = localStorage.getItem(this.brandId + '_LanguageCode');
          var getCountryCode = localStorage.getItem(this.brandId + '_CountryCode');
          this.languageCode = (getLanguageCode != null) ? getLanguageCode : "";
          this.countryCode = (getCountryCode != null) ? getCountryCode : "";
          localStorage.removeItem(this.brandId + '_UrlPath');
        }
        else {
          if (this.brandId == 0) {
            this.brandId = 17;
            localStorage.setItem('BrandId', this.brandId.toString());
            var getBrandId = localStorage.getItem('BrandId');
            localStorage.setItem(this.brandId + '_LanguageCode', 'en');
            getLanguageCode = localStorage.getItem(this.brandId + '_LanguageCode');
            localStorage.setItem(this.brandId + '_CountryCode', 'us');
            getCountryCode = localStorage.getItem(this.brandId + '_CountryCode');
          }
          var getLastLanguageCode = localStorage.getItem(this.brandId + '_LastLanguageCode');
          var getLastCountryCode = localStorage.getItem(this.brandId + '_LastCountryCode');
          this.languageCode = getLastLanguageCode != null ? getLastLanguageCode : "";
          this.countryCode = getLastCountryCode != null ? getLastCountryCode : "";
          localStorage.removeItem('LastLanguageCode');
          localStorage.removeItem('LastCountryCode');
        }
        this._companySetting.GetConfiguration(this.languageCode, this.countryCode).subscribe(responseItems => {
          var configurationResponse = responseItems as any;
          localStorage.removeItem('BrandId');
          localStorage.removeItem(this.brandId + '_UrlLanguageCode');
          localStorage.removeItem(this.brandId + '_UrlCountryCode');
          localStorage.removeItem(this.brandId + '_LanguageCode');
          localStorage.removeItem(this.brandId + '_CountryCode');
          if (configurationResponse.languageCode == "en" && this.countryCodes.includes(configurationResponse.countryCode)) {
            if (configurationResponse.countryCode == 'gb')
              this.brandId = 16;
            else
              this.brandId = 17;
          }
          localStorage.setItem('BrandId', this.brandId.toString());
          var getBrandId = localStorage.getItem('BrandId');
          localStorage.setItem(this.brandId + '_LanguageCode', configurationResponse.languageCode);
          getLanguageCode = localStorage.getItem(this.brandId + '_LanguageCode');
          localStorage.setItem(this.brandId + '_CountryCode', configurationResponse.countryCode);
          getCountryCode = localStorage.getItem(this.brandId + '_CountryCode');
        });
      }
    }

    catch (e) {

    }

  }
}
