<section id="content_wrap" class="nbf_tpl_pagesection_vertical_norwd content_wrap contentwidth">
  <div class="maincnt">

    <div class="terms-wrapper">
      <div class="terms-top">
        <div class="terms-center lf-bg">
          <div class="terms-title">FAQs</div>
          <div class="trms-notice">Frequient Asked Questions</div>
        </div>
      </div>

      <div class="terms-second">
        <div class="terms-center">
          <div class="terms-intro">Bookings</div>

        </div>
      </div>

      <div class="terms-accordion">
        <div class="terms-center">
          <div class="acc-mainn">
            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(1)">
                Q: I haven't received any confirmation of my booking. What should I do?

              </div>
              <div class="acc-cont" *ngIf="showTab == 1 && activeStatus == 1">
                <div class="bwm">
                  Ans: Make sure that you have checked your inbox, junk or spam folder thoroughly. If you still haven't received any booking confirmation then it might not yet be confirmed. In such scenarios, reach out to our team for assistance.
                </div>


              </div>
            </div>
            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(2)">Q: How to change my check-in or check-out time? </div>
              <div class="acc-cont" *ngIf="showTab == 2 && activeStatus == 2">
                <div class="bwm">
                  Ans: You can place your request for early or delayed check-in/check-out at the time of booking. This can also be done by calling the property or reaching out to us after you get a confirmation email. However, changes are subject to the rules of the property.
                </div>
              </div>
            </div>
            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(3)">Q: Should I confirm my booking with the hotel?</div>
              <div class="acc-cont" *ngIf="showTab == 3 && activeStatus == 3">
                <div class="bwm">Ans: No, once you have received a confirmation from our end with all the details in it then you do not need to re-confirm your booking with the hotel. However, it is a good practice to call your hotel before you depart for your destination to advice about your expected arrival time.</div>

              </div>
            </div>
            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(4)">
                Q: Can I choose specific rooms on a property I saw on your website?
              </div>
              <div class="acc-cont" *ngIf="showTab == 4 && activeStatus == 4">
                <div class="bwm">
                  Ans: Yes, you can do that. When you have a special request regarding room type then you should call our team and they will find whether your requested room is available or not. If the room you requested is not available then we can find a similar property for you with the same features.
                </div>
              </div>
            </div>
            <div class="terms-intro">Changes and Cancellations</div>

            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(5)">Q: Where can I find my hotel’s cancellation policy?</div>
              <div class="acc-cont" *ngIf="showTab == 5 && activeStatus == 5">
                <div class="bwm">Ans: You can find the hotel cancellation policy at three distinct places. First, you can see it in your invoice or voucher, second, you can see the cancellation policy on Morerooms.com and lastly you can see the policies on the website of the particular hotel or property you are booking.</div>
              </div>
            </div>
            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(6)">
                Q: Can I make changes to my bookings?
              </div>
              <div class="acc-cont" *ngIf="showTab == 6 && activeStatus == 6">
                <div class="bwm">
                  Ans: Yes, you can make changes to your bookings but please note that changes are subject to the policy of the hotel that you have booked. Contact our customer support at the earliest with your request for change.
                </div>
              </div>
            </div>
            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(7)">
                Q: If I cancel my booking will I pay any charge?
              </div>
              <div class="acc-cont" *ngIf="showTab == 7 && activeStatus == 7">
                <div class="bwm">
                  Ans: If you have booked your accommodation under the free cancellation policy then you won't have to pay any extra charge at the time of cancellation. However, if your booking doesn't cover this, then you will have to pay additional costs to the property.
                </div>
              </div>
            </div>
            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(8)">
                Q: How to make changes to a non-refundable booking?
              </div>
              <div class="acc-cont" *ngIf="showTab == 8 && activeStatus == 8">
                <div class="bwm">
                  Ans: A Non-refundable booking does come with different charges depending on the norms and policies of your booked accommodation. In general cases, altering dates is hard but you can reach out to our team for getting the best available option in your case.
                </div>
              </div>
            </div>

            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(9)">
                Q: Can I move my booking to a future date?
              </div>
              <div class="acc-cont" *ngIf="showTab == 9 && activeStatus == 9">
                <div class="bwm">
                  Ans: Moving your booking to a future date depends entirely on your reservation type. To check this you can either contact our customer support agent for authentic guidance and help.
                </div>
              </div>
            </div>
            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(10)">
                Q: Can I make changes or cancel my booking due to Covid-19 regulations and travel advisory?
              </div>
              <div class="acc-cont" *ngIf="showTab == 10 && activeStatus == 10">
                <div class="bwm">
                  Ans: If your booking is under a flexible booking or easy cancellation policy then your itinerary can be easily changed. However, if you have not booked under free cancellation or easy returns section then you might have to pay a small convenience fee or service charge.
                </div>
              </div>
            </div>

            <div class="terms-intro">Pricing and Payment    </div>

            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(11)">
                Q: Will I need to pay any extra taxes/charges locally after I have paid for my booking on your website?

              </div>
              <div class="acc-cont" *ngIf="showTab == 11 && activeStatus == 11">
                <div class="bwm">
                  Ans: Any extra taxes or charges that you need to pay locally depends on the accommodation you have booked and the city you are headed to. When you are searching for accommodation you can check this in the Taxes section. Mostly all kinds of taxes/charges are included in your final price. However, for certain destinations, the hotels might need to charge a local tax as per regulations. Also, the hotels might charge locally for certain special occasions like Christmas or New Year's Eve Gala Dinners.


                </div>
              </div>
            </div>


            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(12)">
                Q: How to make the payment?


              </div>
              <div class="acc-cont" *ngIf="showTab == 12 && activeStatus == 12">
                <div class="bwm">
                  Ans: Payments depend on properties, some of the accommodations require you to pay a deposit and some doesn't. You will have to pay the remaining amount at the time of check-in.

                </div>
              </div>
            </div>

            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(13)"> Q: Will I have to pay extra for any service I take? </div>
              <div class="acc-cont" *ngIf="showTab == 13 && activeStatus == 13">
                <div class="bwm">
                  Ans: If you order for something apart from what is accessible through your booking then you will have to pay for it according to
                  the rules followed by the property. Do note that your requested service might not be available, so you should make sure that the hotel has your
                  requested item by calling them earlier.
                </div>
              </div>
            </div>
            <div class="terms-intro">Refunds   </div>
            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(14)"> Q: When will I get my refund?    </div>
              <div class="acc-cont" *ngIf="showTab == 14 && activeStatus == 14">
                <div class="bwm">
                  Ans: The refund amount is usually credited to your original payment method within 7 to 10 working days.
                  For more information on deductions or partial payments, you can contact our customer support.
                </div>
              </div>
            </div>
            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(15)">
                Q: I was promised a refund but I haven’t received it yet?
              </div>
              <div class="acc-cont" *ngIf="showTab == 15 && activeStatus == 15">
                <div class="bwm">
                  Ans: We are extremely apologetic if this has happened to you. In such cases, we would advise you to connect with our
                  representatives again through different methods so that your request is highlighted quickly.
                </div>
              </div>
            </div>

            <div class="terms-intro">Communication and Documents        </div>
            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(16)"> Q: Do you provide a VAT/GST invoice, and how can I get it?   </div>
              <div class="acc-cont" *ngIf="showTab == 16 && activeStatus == 16">
                <div class="bwm">
                  Ans: Yes, we do provide a VAT/GST invoice and you can get it as a soft copy along with your booking confirmation that we provide via email.

                </div>
              </div>
            </div>

            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(17)"> Q: How do I find the best hotel deals on Morerooms?                </div>
              <div class="acc-cont" *ngIf="showTab == 17 && activeStatus == 17">
                <div class="bwm">
                  Ans: Morerooms helps you to filter the options that we have on our website for a particular destination.
                  You can fill in your details and according to we segregate the options that we have in our database so that you always get the best options
                  and deals.
                </div>
              </div>
            </div>
            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(18)"> Q: Do I need to present any documents at the time of check-in?                </div>
              <div class="acc-cont" *ngIf="showTab == 18 && activeStatus == 18">
                <div class="bwm">
                  Ans: Morerooms helps you to filter the options that we have on our website for a particular destination.
                  Yes, you must present a valid ID proof at the time of check-in. This document can be your passport or any other government identification card.
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>

  </div>

  



</section>
