//import { convertActionBinding } from '@angular/compiler/src/compiler_util/expression_converter';
import { Component, HostListener } from '@angular/core';
import { environment } from '../environments/environment';
import { CommonService } from './services/common.service';
import { NavigationService } from './services/navigation.service';

import { APP_INITIALIZER, FactoryProvider } from '@angular/core'
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'More Rooms';
  header = '';
  footer = '';
  brandid = "";
  public IsMOSFilter:boolean | undefined;

  constructor(private navigationService: NavigationService, private commonService: CommonService) { 
    this.navigationService.header.subscribe(header => {
      this.header = header;
    });

    this.navigationService.footer.subscribe(footer => {
      this.footer = footer;
    });
    this.navigationService.SetBrand.subscribe(SetBrand => {
      this.brandid = SetBrand;
    });
  //  this.commonService.FromMOSProject();
   // this.IsMOSFilter = this.commonService.isFromMOSSellingProject();
    this.commonService.FromMOSProject()
    {
      this.commonService.isFromMOSProject().subscribe((response: any) => {
        this.IsMOSFilter = response;
        environment.IsFromMosSelling = response;
        this.faviconInitFactory();
      });
    }
  }
  @HostListener('document:click', ['$event.target'])
  clickedOut(target: any) {
      this.navigationService.SetSearchPaxDiv(false);
  }

  faviconInitFactory() {
    const link: any = document.querySelector(`link[rel*='icon']`) || document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'

    if (this.IsMOSFilter) {
      link.href = 'hstfavicon.png' || 'base64'
    } else {
      link.href = 'favicon.png' || 'base64'
    }
    document.getElementsByTagName('head')[0].appendChild(link)

}
}
