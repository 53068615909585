import { Component } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent {
  showTab = 1;
  activeStatus = 1;  
  dynamicUrl: string = '';  
  constructor(private navigationService: NavigationService) {
    this.navigationService.setHeader('O');
    this.dynamicUrl = window.location.pathname.split('/')[1];
    if (this.dynamicUrl.includes('faqs'))
      window.location.pathname = window.location.pathname.replace(window.location.pathname, "/en-us" + window.location.pathname.toLowerCase());
  }

  tabToggle(index: number) {
    this.showTab = index;
    if (this.activeStatus == index)
      this.activeStatus = 0;
    else
      this.activeStatus = index;
  }
}
