<header id="headerM" class="header" [ngClass]="{'addzindex': IsHomeSearchBarMobile}" *ngIf="!IsMOSFilter">

  <div class="header-left">
    <div class="logo-home">
      <a routerLink="/"> <img src="assets/images/logo1.svg" alt="logo" width="192" height="42"> </a>
    </div>
  </div>
  
  <div class="header-right">
    <div class="mos-logo-title-wrapper" *ngIf="!IsMOSFilter">
      <a href="javascript:void(0)" (click)="IsOpenCurrencyPopup=true">
        <img src="assets/images/icon-dollor.svg" alt="currency" width="30" height="28" class="blk-lang-icon">
        <img src="assets/images/white-language-icon.svg" alt="currency" width="30" height="28" class="white-lang-icon">
      </a>
  </div>
    <div class="cus-supp" routerLink="/customer-support">
      <img src="assets/images/icon-support.svg" alt="24/7 Support" width="24" height="22">
      <div>
        <p> Book Online or Call us 24/7</p>
        <p class="call-text">{{callText}}</p>
      </div>
    </div>

   

  </div>
  
</header>
<!-- header ends -->
<div class="sbt-overlay" style="display: block;" *ngIf="IsOpenCurrencyPopup==true">
  <div class="sbt-main">
    <div class="close-breakup"><img src="assets/images/close-new-detail.svg" alt="close" height="14" width="14" (click)="IsOpenCurrencyPopup=false"></div>
    <div class="price-top-title">Display Settings </div>
    <div class="sbt-mid">
      <div class="edf-row">
        <div class="input-bx">
          <label>Region <sup>*</sup> </label>
          <select class="region-select" (change)="ChangeCurrency($event)">
            <option value="United States" [selected]="SelectedRegion('United States')">         United States         </option>
            <option value="United Kingdom" [selected]="SelectedRegion('United Kingdom')">         United Kingdom         </option>
          </select>
        </div>
      </div>
      <div class="feature-flx">
        <div class="lang-1">
          <label for="lan"> Language </label>
          <input class="text-field" type="text" id="lan" name="sbt-radio" placeholder="English" value="English" disabled>
        </div>
        <div class="currency-1">
          <label for="curr">Currency </label>
          <input class="text-field" type="text" id="curr" name="sbt-radio" placeholder="{{CurrencyValue}}" value="{{CurrencyValue}}" disabled>
        </div>
      </div>
      <button class="conti-btn blck" (click)="CurrencySave()">Save</button>
    </div>
  </div>
</div>
