import { Component } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent {  
  dynamicUrl: string = ''; 
  constructor(private navigationService: NavigationService) {
    this.navigationService.setHeader('O');
    this.dynamicUrl = window.location.pathname.split('/')[1];
    if (this.dynamicUrl.includes('aboutus'))
      window.location.pathname = window.location.pathname.replace(window.location.pathname, "/en-us" + window.location.pathname.toLowerCase());   
  }

}
