<section id="content_wrap" class="nbf_tpl_pagesection_vertical_norwd content_wrap contentwidth">
  <div class="maincnt">
    <div class="terms-wrapper">


      <div class="terms-second">
        <div class="terms-center">
          <div class="terms-intro2">About Us</div>
          <div class="term-para">
            <p>
              Founded in 2021, Morerooms is an online portal for booking your next great accommodation across the globe. At Morerooms you are going to get anything and everything suiting your budget
              and requirements. Our team has put in extreme efforts in bringing for you over 4 million properties ranging from hotels, apartments, hostels, villas, and more at some of your favorite
              destinations around the world. The team has expertise in tailoring the most precise itinerary and pairing it with affordable prices in the industry, guaranteeing you accuracy and reliability for
              all your bookings done with Morerooms. We are diligently working towards providing you with a seamless experience. Your next unforgettable stay is just a call away.
            </p>
          </div>
          <div class="sml-hd">Company Background</div>
          <div class="term-para">
            <p>
              Morerooms is a subsidiary of the successful and long-established travel brand Moresand Ltd, based in global locations including London,
              Sri Lanka, India, and USA. Using our 34 years of expertise in the travel industry, we wanted to create a space for our customers
              to book the right accommodation at the most affordable prices.
            </p>
            <p></p>
          </div>
          <div class="our-services-about">
            <div class="osa-title">Our Services</div>
            <div class="os-row">
              <div class="os-box">
                <div class="os-img"><img src="/assets/images/os-one.png" alt="image" /></div>
                <div class="os-det">
                  Best deals on millions<br> of properties
                </div>
              </div>
              <div class="os-box">
                <div class="os-img"><img src="/assets/images/os-two.png" alt="image" /></div>
                <div class="os-det">
                  Guaranteed best<br>
                  customer support
                </div>
              </div>
              <div class="os-box">
                <div class="os-img"><img src="/assets/images/os-three.png" alt="image" /></div>
                <div class="os-det">
                  Three decades of experience<br>
                  in the travel domain
                </div>
              </div>
              <div class="os-box">
                <div class="os-img"><img src="/assets/images/os-four.png" alt="image" /></div>
                <div class="os-det">
                  Highest standards for<br>
                  secure bookings
                </div>
              </div>
              <div class="os-box">
                <div class="os-img"><img src="/assets/images/os-five.png" alt="image" /></div>
                <div class="os-det">
                  Wide variety of choices<br>
                  across the world
                </div>
              </div>
            </div>
          </div>

          <div class="vision-mission">
            <div class="os-row">
              <div class="os-box">
                <div class="os-img"><img src="/assets/images/vision-os.png" alt="image" /></div>
                <div class="os-det">
                  <div class="vm-title">Vision</div>
                  <p>
                    To make loyal relationships with our customers by offering them the best in<br>
                    industry services.
                  </p>
                </div>
              </div>
              <div class="os-box">
                <div class="os-img"><img src="/assets/images/mission-os.png" alt="image" /></div>
                <div class="os-det">
                  <div class="vm-title">Mission</div>
                  <p>To be a one-stop solution for all accommodation needs.</p>
                </div>
              </div>
            </div>
          </div>

        </div>


      </div>
    </div>
    </div>
</section>
