 <!--  Start  DESKTOP footer links   -->
<footer class="mob-footer hidefooter"  *ngIf="IsMOSFilter==false"  id="footerdiv">
  <div class="container"  >
    <div class="row">
      
     
        <div class="footer-left">

          <ul class="list-group">
            <li>
              <p>Support & FAQs</p>
            </li>
            <li class="list-group-item" style="display:none;"> <a href="#">Your bookings </a> </li>
            <li class="list-group-item"> <a routerLink="/aboutus">About us</a> </li>
            <li class="list-group-item"> <a routerLink="/faqs">FAQs </a> </li>
            <li class="list-group-item" style="display:none;"> <a href="#">Contact us </a> </li>
          </ul>
          <ul class="list-group">
            <li>
              <p>User terms & Privacy</p>
            </li>

            <li class="list-group-item"> <a routerLink="/terms-conditions">Terms & Conditions </a> </li>
            <li class="list-group-item"> <a routerLink="/privacy-policy">Privacy Policy</a> </li>
            <li class="list-group-item"> <a routerLink="/cookies-policy">Cookie Policy</a> </li>
          </ul>

         
        </div>
     
      
        <div class="footer-right">
          <p>Exclusive hotel offers are waiting for you!</p>
          <form action="" [formGroup]="SubscribeForm" (ngSubmit)="SubscribeUser()" #SubscribeForms="ngForm" class="subcribe-form">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Enter Email Address" formControlName="EmailID"
                     [ngClass]="{ 'is-invalid': SubscribeForms.submitted && SubscribeForm.controls.EmailID.errors }">
              <div *ngIf="SubscribeForms.submitted && SubscribeForm.controls.EmailID.errors">
                <div class="val-red" *ngIf="SubscribeForms.submitted && SubscribeForm.controls.EmailID.errors.required"> Please enter email Id</div>
                <div class="val-red" *ngIf="SubscribeForms.submitted && SubscribeForm.controls.EmailID.errors.pattern"> Please enter valid email Id</div>
              </div>
              <div *ngIf="SubscribeMessage !=''">
                <div class="val-red"> {{SubscribeMessage}}</div>
              </div>
              <input type="submit" name="submit" value="Subscribe" *ngIf="this.IsButtonVisible">
            </div>
            <div class="nwsltr" for="chk-subscribe">
              <input type="checkbox" class="nlcheck" id="chk-subscribe" formControlName="SubscribeCheckbox" value="" checked />
              <span> By clicking, you agree to receive promotional offers and discount deals from morerooms.</span>
            </div>
          </form>


        </div>



    </div>

    <div class="foot-mid">
      <div class="card-section">
        <div class="cs-text">Card Accepted </div>
        <div class="card-icons">
          <img src="assets/images/paypal-foot.svg" alt="PayPal" width="56" height="36" />
          <img src="assets/images/visa-foot.svg" alt="visa" width="56" height="36" />
          <img src="assets/images/amex-foot.svg" alt="amex" width="56" height="36" />
          <img src="assets/images/mastercard-foot.svg" alt="mastercard" width="56" height="36" />
          <img src="assets/images/maestro-foot.svg" alt="maestro" width="56" height="36" />
          <img src="assets/images/apple-pay-foot.svg" alt="apple-pay" width="56" height="36" />
          <img src="assets/images/g-pay-foot.svg" alt="g-pay" width="56" height="36" />
          <img src="assets/images/discover-foot.svg" alt="discover" width="56" height="36" />
          <img src="assets/images/stripe-foot.svg" alt="stripe" width="56" height="36" />
        </div>
      </div>

      <div class="social">
        <p>Follow us</p>
        <ul>
          <li><a href="https://www.facebook.com/MoreroomsOfficial/"> <img src="assets/images/facebook-foot.svg" alt="facebook" height="25" /> </a></li>
          <li><a href="https://twitter.com/More_rooms"> <img src="assets/images/twitter-foot.svg" alt="twitter" height="18" /> </a></li>
          <li><a href="https://www.instagram.com/moreroomsofficial/"> <img src="assets/images/instagram-foot.svg" alt="instagram" height="19" /></a></li>
         
          <li><a href="https://www.linkedin.com/company/morerooms/"> <img src="assets/images/linkedin-foot.svg" alt="linkedin" height="17" /> </a></li>
        </ul>
      </div>
    </div>
    <div class="col-lg-12">
      <p class="bottom-txt pt-0" *ngIf="this.CompanySettings.Brand_ID==17"> © 2020-{{this.CompanySettings.Year}} Moresand LLC. Morerooms registered in New Jersey , United States Of America .Head Office, Moresand LLC, 35 Journal Square Suite 424 Jersey City, NJ 07306.  </p>
      <p class="bottom-txt pt-0" *ngIf="this.CompanySettings.Brand_ID==16"> © 2020-{{this.CompanySettings.Year}} Moresand Limited. Morerooms registered in London , United Kingdom, Head Office- Moresand Limited, 10-11 Percy Street London W1T 1DN.  </p>
    </div>
   

  </div>

</footer>
<!--  END DESKTOP footer links   -->






<div class="userprofile-slide" id="" style="display:none;">


  <div class="user-footer-mob">
    <ul>
      <h5> Support & FAQs</h5>
      <li>Your bookings </li>
      <li>FAQs</li>
      <li>Contact us</li>
      <li>About us</li>
    </ul>
    <ul>
      <h5> User terms & Privacy</h5>
      <li>Terms & Conditions </li>
      <li>Privacy</li>
      <li>Cookies</li>
    </ul>
  </div>

  <div class="footer-right">
    <p>Want to receive exclusive hotel offers? Subscribe to our newsletter</p>
    <form action="" class="subcribe-form">
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Enter Email Address">
        <button class="btn btn-danger" type="button"> <em class="fa fa-angle-right"></em> </button>
      </div>
    </form>

    <div class="social">
      <p>Follow us</p>
      <ul>
        <li><a href="https://www.facebook.com/MoreroomsOfficial/"><em class="fab fa-facebook-f"></em> </a></li>
        <li><a href="https://twitter.com/More_rooms"><em class="fab fa-twitter"></em> </a></li>
        <li><a href="https://www.instagram.com/moreroomsofficial/"><em class="fab fa-instagram"></em> </a></li>
        <li><a href="https://www.linkedin.com/company/morerooms/"><em class="fab fa-linkedin"></em> </a></li>
      </ul>
    </div>
    <div class="clearfix"></div>
    <div class="col-lg-12">
      <p class="bottom-txt pt-0">© 2020-{{this.CompanySettings.Year}} Moresand LLC. Morerooms registered in New Jersey , United States Of America .Head Office, Moresand LLC, 35 Journal Square Suite 424 Jersey City, NJ 07306. </p>
    </div>
  </div>

</div>

