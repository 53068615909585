import { MbscModule } from '@mobiscroll/angular';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { allIcons, NgxBootstrapIconsModule } from 'ngx-bootstrap-icons';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { InnerHeaderComponent } from './inner-header/inner-header.component';
import { LocalStoreManager } from './services/local-store-manager.service';
import { HttpClientModule } from '@angular/common/http';
import { AboutusComponent } from './aboutus/aboutus.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { CookiesPolicyComponent } from './cookies-policy/cookies-policy.component';
import { FaqsComponent } from './faqs/faqs.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { OtherHeaderComponent } from './other-header/other-header.component';
import { CustomerSupportComponent } from './customer-support/customer-support.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ToastrModule } from 'ngx-toastr';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { APP_BASE_HREF } from '@angular/common';
import { NewHeaderComponent } from './new-header/new-header.component';
export let myServiceFactory = () => {
  //var languageCode = localStorage.getItem(localStorage.getItem('BrandId') + '_LanguageCode');
  //var countryCode = localStorage.getItem(localStorage.getItem('BrandId') + '_CountryCode');
  var urlPath = window.location.pathname.split(' / ')[0].toString().toLowerCase();
  try { 
  if (urlPath != '/') {
    var countryCodes: any[] = ['us', 'gb'];
    var urlLanguageCode = urlPath.toString().split('/')[1].split('-')[0].toLowerCase();
    var urlCountryCode = urlPath.toString().split('/')[1].split('-')[1].toLowerCase();
    if (urlLanguageCode == 'en' && countryCodes.includes(urlCountryCode)) {

      if (urlLanguageCode != null && urlCountryCode != null) {
        return '/' + urlLanguageCode + '-' + urlCountryCode + '/' + (window.location.pathname.toLowerCase().split(' / ')[1] || '');
      }
      else
        return '/en-us/' + (window.location.pathname.toLowerCase().split(' / ')[1] || '');
    }
    else
      return (window.location.pathname.toLowerCase().split(' / ')[1] || '');
  }
  else {
    return '/en-us/' + (window.location.pathname.toLowerCase().split(' / ')[1] || '');
    }

  } catch (e) {
    return (window.location.pathname.toLowerCase().split(' / ')[1] || '');
  }

};

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    InnerHeaderComponent,
    AboutusComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    ScrollTopComponent,
    CookiesPolicyComponent,
    FaqsComponent,
    PageNotFoundComponent,
    OtherHeaderComponent,
    CustomerSupportComponent,
    NewHeaderComponent
  ],
  imports: [  
    MbscModule,   
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    AppRoutingModule,
    NgxBootstrapIconsModule.pick(allIcons),
    BrowserAnimationsModule,
    CarouselModule,
    HttpClientModule,
    ModalModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot(),
    NgxIntlTelInputModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [LocalStoreManager,
    {
      provide: APP_BASE_HREF,      
      useFactory: myServiceFactory
    }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {
}
