import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../services/common.service';
import { CompanySettingsService } from '../services/company-settings.service';
declare const mousflowCalling: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  languageCode: string = '';
  countryCode: string = '';
  countryCodes: any[] = ['us', 'gb'];
  dynamicUrl: string = '';
  IsTest: boolean = true;
  SubscribeForm: UntypedFormGroup;
  BrandId: number = 0;
  CompanySettings = CompanySettingsService;
  SubscribeMessage: string = '';
  IsButtonVisible: boolean = true; 
  IsMOSFilter: boolean | undefined;
  constructor(private fb: UntypedFormBuilder, private toastr: ToastrService, private commonService: CommonService, private _companySetting: CompanySettingsService) {
    this.IsTest = true;
    this.IsMOSFilter = this.commonService.isFromMOSSellingProject();
    this.dynamicUrl = window.location.pathname.split('/')[1];
    this.languageCode = this.dynamicUrl.split('-')[0];
    this.countryCode = this.dynamicUrl.split('-')[1];
    if (this.languageCode == 'en' && this.countryCodes.includes(this.countryCode)) {
      if (this.countryCode == 'gb')
        this.BrandId = 16;
      else
        this.BrandId = 17;
      mousflowCalling();
    }
 
  
    this._companySetting.GetConfiguration(this.languageCode, this.countryCode).subscribe(responseItems => {
      var configurationResponse = responseItems as any;
      if (configurationResponse.languageCode == 'en' && this.countryCodes.includes(configurationResponse.countryCode)) {
        if (configurationResponse.countryCode == 'gb')
          this.BrandId = 16;
        else
          this.BrandId = 17;
      }

      this.CompanySettings.Brand_ID = this.BrandId;
      this.CompanySettings.CurrencySymbol = configurationResponse.currencySymbol;
      this.CompanySettings.Currency = configurationResponse.currency;
      this.CompanySettings.CultureCode = configurationResponse.cultureCode;
      this.CompanySettings.MetaChannel = configurationResponse.metaChannel;
      this.languageCode = configurationResponse.languageCode;
      this.countryCode = configurationResponse.countryCode;
      this.CompanySettings.Year = new Date().getFullYear();
    });
    this.SubscribeForm = this.fb.group({
      EmailID: new UntypedFormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      SubscribeCheckbox: new UntypedFormControl('')
    });
  }
  ngOnInit(): void {
    this.IsTest = true;

  }
  SubscribeUser() {
    if (this.SubscribeForm.invalid) {
      return;
    }
    let SubscribeCheckbox = this.SubscribeForm.get('SubscribeCheckbox')?.value ?? '';
    if (!SubscribeCheckbox) {
      this.SubscribeMessage = "Please click on checkbox";
      return;
    }
   
    
    let EmailID = this.SubscribeForm.get('EmailID')?.value ?? '';
    this.GetSubscribes(EmailID);  
  }

  GetSubscribes(emailID: string) {
    this.IsButtonVisible = false;
    this.commonService.SubscribeSendMail(emailID, this.languageCode, this.countryCode).subscribe((response: any) => {
      if (response != "" || response != null) {
        this.IsButtonVisible = true;
        if (response == "ERROR")
          this.SubscribeMessage = "User is already subscribed.";
        else if (response == "ValidateEmailError")
          this.SubscribeMessage = "Please enter a valid email address.";   
        else if (response == "ErrorEmailTemplate" || response == "ErrorSendEmail")
          this.SubscribeMessage = "Technical error.";
        else if (response == "SUCCESS") 
          this.SubscribeMessage = "Thank you for subscribe.";       
      }
      else {
        this.IsButtonVisible = true;
        this.toastr.info(response.status.message);
      }
    }, error => {
        this.IsButtonVisible = true;
        this.toastr.info(error);
    });
  }

}
