import { Injectable } from '@angular/core';
import { EncryptStorage } from 'src/utils/index';

@Injectable({
  providedIn: 'root'
})
export class LocalStoreManager {

  private readonly SECRET_KEY = 'Crystal@123';


  //=====Local Storage Start===============
  public setLocalItem(key: string, data: any) {
    EncryptStorage(this.SECRET_KEY, { storageType: 'localStorage' }).setItem(key, data);
  }
  public getLocalItem(key: string): any {
    return EncryptStorage(this.SECRET_KEY, { storageType: 'localStorage' }).getItem(key);
  }
  public removeLocalItem(key: string) {
    EncryptStorage(this.SECRET_KEY, { storageType: 'localStorage' }).removeItem(key);
  }
  public removeLocalItemFromPattern(key: string) {
    EncryptStorage(this.SECRET_KEY, { storageType: 'localStorage' }).removeItemFromPattern(key);
  }
  public clearLocal() {
    EncryptStorage(this.SECRET_KEY, { storageType: 'localStorage' }).clear();
  }
  public gekeyLocal(key: number): any {
    return EncryptStorage(this.SECRET_KEY, { storageType: 'localStorage' }).key(key);
  }
  public getKeylengthLocal(): number {
    return EncryptStorage(this.SECRET_KEY, { storageType: 'localStorage' }).length;
  }
  //=====Local Storage End===============
  //=====Session Storage Start===============
  public setSessionItem(key: string, data: any) {
    EncryptStorage(this.SECRET_KEY, { storageType: 'sessionStorage' }).setItem(key, data);
  }
  public getSessionItem(key: string): any {
    return EncryptStorage(this.SECRET_KEY, { storageType: 'sessionStorage' }).getItem(key);
  }
  public removeSessionItem(key: string) {
    EncryptStorage(this.SECRET_KEY, { storageType: 'sessionStorage' }).removeItem(key);
  }
  public removeSessionItemFromPattern(key: string) {
    EncryptStorage(this.SECRET_KEY, { storageType: 'sessionStorage' }).removeItemFromPattern(key);
  }
  public clearSession() {
    EncryptStorage(this.SECRET_KEY, { storageType: 'sessionStorage' }).clear();
  }
  public gekeySession(key: number): any {
    return EncryptStorage(this.SECRET_KEY, { storageType: 'sessionStorage' }).key(key);
  }
  public getKeylengthSession(): number {
    return EncryptStorage(this.SECRET_KEY, { storageType: 'sessionStorage' }).length;
  }
  //=====Session Storage End===============

  public encryptString(data: string): string {
    return EncryptStorage(this.SECRET_KEY).encryptString(data);
  }
  public decryptString(data: string): string {
    return EncryptStorage(this.SECRET_KEY).decryptString(data);
  }

}
