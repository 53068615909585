import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../services/common.service';
import { CompanySettingsService } from '../services/company-settings.service';
import { NavigationService } from '../services/navigation.service';
import { environment } from '../../environments/environment';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customer-support',
  templateUrl: './customer-support.component.html',
  styleUrls: ['./customer-support.component.css']
})
export class CustomerSupportComponent implements OnInit {
  refText: string = '';
  refCondition: string = '';
  languageCode: string = '';
  countryCode: string = ''; 
  countryCodes: any[] = ['us', 'gb'];
  dynamicUrl: string = '';
  TabValue: string = '1';
  Ispopup: boolean = false;
  minDate: Date;
  maxDate: Date;
  ReferenceNo: string = '';
  TypeAmendment: string = '';
  TypeSpecialRequest: string = '';
  TypeCancellation: string = '';
  TypeRefund: string = '';
  TypeGeneralEnquiry: string = '';
  TypeFeedbackandComplaints: string = '';

  AmendmentForm: UntypedFormGroup;
  SpecialRequestForm: UntypedFormGroup;
  CancellationForm: UntypedFormGroup;
  RefundForm: UntypedFormGroup;
  GeneralEnquiryForm: UntypedFormGroup;
  FeedbackandComplaintsForm: UntypedFormGroup;
  CompanySettings = CompanySettingsService;
  PleaseWait: boolean = false;
  GeneralEnquiryDate: string = 'Check-in date';
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  CountryISOName: any;
  PreferredCountriesName: any[] = [];
  PhoneNumberFormat = PhoneNumberFormat;
  phonenumber: any;

  AllCategories: any[] = [];
  Amendment: any[] = [];
  SpecialRequest: any[] = [];
  Cancellation: any[] = [];
  Refund: any[] = [];
  GeneralEnquiry: any[] = [];
  FeedbackandComplaints: any[] = [];

  AminitiesGE: any[] = [];
  AminitiesFB: any[] = [];
  dropdownSettings: IDropdownSettings = {};
  BrandId: number = 0;
  constructor(private router: Router, private navigationService: NavigationService, private fb: UntypedFormBuilder, private commonService: CommonService, private toastr: ToastrService, private _companySetting: CompanySettingsService) {
    this.dynamicUrl = window.location.pathname.split('/')[1];
    if (this.dynamicUrl.includes('customer-support'))
      window.location.pathname = window.location.pathname.replace(window.location.pathname, "/en-us" + window.location.pathname.toLowerCase());
    this.languageCode = this.dynamicUrl.split('-')[0];
    this.countryCode = this.dynamicUrl.split('-')[1];
    if (this.languageCode == 'en' && this.countryCodes.includes(this.countryCode)) {
      if (this.countryCode == 'gb') {
        this.BrandId = 16;
        this.CountryISOName = this.CountryISO.UnitedKingdom;
        this.PreferredCountriesName = ['gb'];
      }
      else {
        this.BrandId = 17;
        this.CountryISOName = this.CountryISO.UnitedStates;
        this.PreferredCountriesName = ['us'];
      }
    }

    var getBrandId = localStorage.getItem('BrandId');
    getBrandId = getBrandId != null ? getBrandId : '0';
    
    this.navigationService.setHeader('O');
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 15);
    this.maxDate.setDate(this.minDate.getDate() + 365);

    this._companySetting.GetConfiguration(this.languageCode, this.countryCode).subscribe(responseItems => {
      var configurationResponse = responseItems as any;
      //if ((getBrandId != '0') && (getBrandId != this.BrandId.toString())) {
      //  this.router.navigate(['/hotel']);
      //  return;
      //}
      //else {
        if (configurationResponse.languageCode == 'en' && this.countryCodes.includes(configurationResponse.countryCode)) {
          if (configurationResponse.countryCode == 'gb') {
            this.BrandId = 16;
            this.CountryISOName = this.CountryISO.UnitedKingdom;
            this.PreferredCountriesName = ['gb'];
          }
          else {
            this.BrandId = 17;
            this.CountryISOName = this.CountryISO.UnitedStates;
            this.PreferredCountriesName = ['us'];
        }
        }

        this.CompanySettings.Brand_ID = this.BrandId;
        this.CompanySettings.CurrencySymbol = configurationResponse.currencySymbol;
        this.CompanySettings.Currency = configurationResponse.currency;
        this.CompanySettings.CultureCode = configurationResponse.cultureCode;
        this.CompanySettings.MetaChannel = configurationResponse.metaChannel;
        this.languageCode = configurationResponse.languageCode;
        this.countryCode = configurationResponse.countryCode;
     // }
    });

    if (this.BrandId == 17) {
      this.refCondition = "^[mrusMRUS]{4}[a-zA-Z0-9]*$";
      this.refText = "MRUS454FF5";
    }
    else if (this.BrandId == 16) {
      this.refCondition = "^[mrukMRUK]{4}[a-zA-Z0-9]*$";
      this.refText = "MRUK454FF5";
    }

    if (this.BrandId == 17)
      this.TypeAmendment = "211";
    else if (this.BrandId == 16)
      this.TypeAmendment = "1";

    if (this.BrandId == 17)
      this.TypeSpecialRequest = "215";
    else if (this.BrandId == 16)
      this.TypeSpecialRequest = "7";

    if (this.BrandId == 17)
      this.TypeCancellation = "222";
    else if (this.BrandId == 16)
      this.TypeCancellation = "14";

    if (this.BrandId == 17)
      this.TypeRefund = "226";
    else if (this.BrandId == 16)
      this.TypeRefund = "24";

    if (this.BrandId == 17)
      this.TypeGeneralEnquiry = "227";
    else if (this.BrandId == 16)
      this.TypeGeneralEnquiry = "25";

    if (this.BrandId == 17)
      this.TypeFeedbackandComplaints = "231";
    else if (this.BrandId == 16)
      this.TypeFeedbackandComplaints = "31";

    this.AmendmentForm = this.fb.group({
      Type: new UntypedFormControl(this.TypeAmendment, Validators.required),
      Leadguest: new UntypedFormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      ReferenceNumber: new UntypedFormControl('', [Validators.required, Validators.pattern(this.refCondition)]),
      EmailID: new UntypedFormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      ContactNumber: new UntypedFormControl('', [Validators.required]),
      Checkindate: new UntypedFormControl('', Validators.required),
      AdditionalComments: new UntypedFormControl(''),
      NameChange: new UntypedFormControl(''),
      GuestDob: new UntypedFormControl('')
    });
    this.SpecialRequestForm = this.fb.group({
      Type: new UntypedFormControl(this.TypeSpecialRequest, Validators.required),
      Leadguest: new UntypedFormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      ReferenceNumber: new UntypedFormControl('', [Validators.required, Validators.pattern(this.refCondition)]),
      EmailID: new UntypedFormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      ContactNumber: new UntypedFormControl('', [Validators.required]),
      Checkindate: new UntypedFormControl('', Validators.required),
      Checkintime: new UntypedFormControl(''),
      Checkouttime: new UntypedFormControl(''),
      Upgrade: new UntypedFormControl(false),
      AdditionalComments: new UntypedFormControl(''),
      CheckInTimeValue: new UntypedFormControl('1'),
      CheckInTimeType: new UntypedFormControl('AM'),
      CheckOutTimeValue: new UntypedFormControl('1'),
      CheckOutTimeType: new UntypedFormControl('AM')
    });
    this.CancellationForm = this.fb.group({
      Type: new UntypedFormControl(this.TypeCancellation, Validators.required),
      Leadguest: new UntypedFormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      ReferenceNumber: new UntypedFormControl('', [Validators.required, Validators.pattern(this.refCondition)]),
      EmailID: new UntypedFormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      ContactNumber: new UntypedFormControl('', [Validators.required]),
      Checkindate: new UntypedFormControl('', Validators.required),
      AdditionalComments: new UntypedFormControl('')
    });
    this.RefundForm = this.fb.group({
      Type: new UntypedFormControl(this.TypeRefund, Validators.required),
      Leadguest: new UntypedFormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      ReferenceNumber: new UntypedFormControl('', [Validators.required, Validators.pattern(this.refCondition)]),
      EmailID: new UntypedFormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      ContactNumber: new UntypedFormControl('', [Validators.required]),
      AdditionalComments: new UntypedFormControl('')
    });
    this.GeneralEnquiryForm = this.fb.group({
      Type: new UntypedFormControl(this.TypeGeneralEnquiry, Validators.required),
      Leadguest: new UntypedFormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      ReferenceNumber: new UntypedFormControl('', [Validators.required, Validators.pattern(this.refCondition)]),
      EmailID: new UntypedFormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      ContactNumber: new UntypedFormControl('', [Validators.required]),
      Checkindate: new UntypedFormControl('', Validators.required),
      AmenityNameType: new UntypedFormControl(''),
      AdditionalComments: new UntypedFormControl('')
    });
    this.FeedbackandComplaintsForm = this.fb.group({
      Type: new UntypedFormControl(this.TypeFeedbackandComplaints, Validators.required),
      Leadguest: new UntypedFormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      ReferenceNumber: new UntypedFormControl('', [Validators.required, Validators.pattern(this.refCondition)]),
      EmailID: new UntypedFormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      ContactNumber: new UntypedFormControl('', [Validators.required]),
      Checkindate: new UntypedFormControl('', Validators.required),
      AmenityInclusionName: new UntypedFormControl(''),
      AdditionalComments: new UntypedFormControl('')
    });

  }
  ngOnInit(): void {
    
    let brandId = this.BrandId;
    
    
    this.commonService.GetCategories(this.languageCode, this.countryCode).subscribe((response: any) => {
      if (response.data && response.data.length > 0) {
        this.AllCategories = response.data;

        this.Amendment = this.AllCategories.filter((item: any) => { return item.category == 'Amendment' });
        this.SpecialRequest = this.AllCategories.filter((item: any) => { return item.category == 'Special Request' });
        this.Cancellation = this.AllCategories.filter((item: any) => { return item.category == 'Cancellation' });
        this.Refund = this.AllCategories.filter((item: any) => { return item.category == 'Refund' });
        if (this.Refund.length > 2) {
          this.Refund = this.Refund.filter((item: any, index: number) => { return index == 2 });
        }
        this.GeneralEnquiry = this.AllCategories.filter((item: any) => { return item.category == 'General Enquiry' });
        this.FeedbackandComplaints = this.AllCategories.filter((item: any) => { return item.category == 'Feedback' });
        if (this.FeedbackandComplaints.length > 0 && brandId == 16) {
          this.AminitiesFB = this.FeedbackandComplaints.filter((item: any) => { return item.categoryId == 33 })[0].inputs;
        }
        else if (this.FeedbackandComplaints.length > 0 && brandId == 17) {
          this.AminitiesFB = this.FeedbackandComplaints.filter((item: any) => { return item.categoryId == 233 })[0].inputs;
        }
      }
      else {
        this.toastr.info(response.status.message);
      }
    }, error => { this.toastr.info(error); });

    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }
  AmendmentFormTypeChange() {
    let Type = this.AmendmentForm.get('Type')?.value ?? '';
    if (Type == "4" || Type == "2" || Type == "3" || Type == "212" || Type == "213") {
      this.AmendmentForm.controls["NameChange"].setValidators([Validators.required, Validators.pattern('^[a-zA-Z ]*$')]);
    }
    else {
      this.AmendmentForm.controls["NameChange"].clearValidators();
    }
    if (Type == "4" || Type == "213") {
      this.AmendmentForm.controls["GuestDob"].setValidators([Validators.required]);
    }
    else {
      this.AmendmentForm.controls["GuestDob"].clearValidators();
    }
    this.AmendmentForm.get("NameChange")?.updateValueAndValidity();
    this.AmendmentForm.get("GuestDob")?.updateValueAndValidity();
  }


  SpecialRequestFormTypeChange() {
    let Type = this.SpecialRequestForm.get('Type')?.value ?? '';
    if (Type == "9" || Type == "217") {
      this.SpecialRequestForm.controls["Checkintime"].setValidators([Validators.required]);
      this.SpecialRequestForm.controls["Checkouttime"].setValidators([Validators.required]);
    }
    else {
      this.SpecialRequestForm.controls["Checkintime"].clearValidators();
      this.SpecialRequestForm.controls["Checkouttime"].clearValidators();
    }
    this.SpecialRequestForm.get("Checkintime")?.updateValueAndValidity();
    this.SpecialRequestForm.get("Checkouttime")?.updateValueAndValidity();
  }


  GeneralEnquiryTypeChange() {
    let Type = this.GeneralEnquiryForm.get('Type')?.value ?? '';
    if (Type == "28" || Type == "29" || Type == "30" || Type == "229" || Type == "230") {
      this.GeneralEnquiryDate = "Check-out date";
    }
    else {
      this.GeneralEnquiryDate = "Check-in date";
    }
  }

  TimeChange() {
    let Type = this.SpecialRequestForm.get('Type')?.value ?? '';
    let CheckInTime = this.SpecialRequestForm.get('Checkintime')?.value ?? '';
    let CheckOutTime = this.SpecialRequestForm.get('Checkouttime')?.value ?? '';
    if ((Type == "9" || Type == "217") && CheckInTime != '' && CheckOutTime == '') {
      this.SpecialRequestForm.controls["Checkintime"].setValidators([Validators.required]);
      this.SpecialRequestForm.controls["Checkouttime"].clearValidators();
    }
    if ((Type == "9" || Type == "217") && CheckOutTime != '' && CheckInTime == "") {
      this.SpecialRequestForm.controls["Checkouttime"].setValidators([Validators.required]);
      this.SpecialRequestForm.controls["Checkintime"].clearValidators();
    }
    if ((Type == "9" || Type == "217") && CheckOutTime != '' && CheckInTime != "") {
      this.SpecialRequestForm.controls["Checkintime"].setValidators([Validators.required]);
      this.SpecialRequestForm.controls["Checkouttime"].setValidators([Validators.required]);
    }
    else if (CheckInTime == "" && CheckOutTime == "") {
      this.SpecialRequestForm.controls["Checkintime"].clearValidators();
      this.SpecialRequestForm.controls["Checkouttime"].clearValidators();
    }
    this.SpecialRequestForm.get("Checkintime")?.updateValueAndValidity();
    this.SpecialRequestForm.get("Checkouttime")?.updateValueAndValidity();
  }


  AmendmentFormonSubmit() {
    if (this.AmendmentForm.invalid) {
      if (this.AmendmentForm.controls.ContactNumber.errors != null) {
        this.AmendmentForm.controls['ContactNumber'].setErrors({ 'incorrect': true });
      }
      return;
    }
    this.PleaseWait = true;
    let Type = this.AmendmentForm.get('Type')?.value ?? 0;
    let Leadguest = this.AmendmentForm.get('Leadguest')?.value ?? '';
    let ReferenceNumber = this.AmendmentForm.get('ReferenceNumber')?.value.toLocaleUpperCase() ?? '';
    let EmailID = this.AmendmentForm.get('EmailID')?.value ?? '';
    let ContactNumber = this.AmendmentForm.get('ContactNumber')?.value ?? '';
    if (ContactNumber != "") {
      ContactNumber = ContactNumber.internationalNumber;
    }
    let Checkindate = this.AmendmentForm.get('Checkindate')?.value ?? '';
    let AdditionalComments = this.AmendmentForm.get('AdditionalComments')?.value ?? '';
    let NameChange = this.AmendmentForm.get('NameChange')?.value ?? '';
    let GuestDob = this.AmendmentForm.get('GuestDob')?.value ?? '';
    Checkindate = this.SetDateFormat(Checkindate);
    if (GuestDob != "")
      GuestDob = this.SetDateFormat(GuestDob);
    let data = {
      /* brandId: this.BrandId,*/
      sourceMediaId: this.CompanySettings.MetaChannel,
      //campaignId: this.BrandId,
      //marketingMediaId: this.BrandId,
      domain: environment.BaseURL + this.dynamicUrl,
      brandPhoneNumber: this.CompanySettings.EmailerPhoneNumber,
      categoryId: Type,
      bookingid: ReferenceNumber,
      comments: AdditionalComments,
      customerName: Leadguest,
      customerPhoneNumber: ContactNumber,
      email: EmailID,
      metadata: {
        checkindate: Checkindate,
        checkintime: "",
        checkouttime: "",
        amenityName: null,
        nameAmendment: NameChange,
        childDobAmendment: GuestDob,
        customerAcceptChanges: ""
      }
    };
    this.SendMail(data);

  }
  SpecialRequestFormonSubmit() {
    if (this.SpecialRequestForm.invalid) {
      if (this.SpecialRequestForm.controls.ContactNumber.errors != null) {
        this.SpecialRequestForm.controls['ContactNumber'].setErrors({ 'incorrect': true });
      }
      return;
    }
    this.PleaseWait = true;
    let Checkintime = '';
    let Checkouttime = '';
    let Type = this.SpecialRequestForm.get('Type')?.value ?? 0;
    let Leadguest = this.SpecialRequestForm.get('Leadguest')?.value ?? '';
    let ReferenceNumber = this.SpecialRequestForm.get('ReferenceNumber')?.value.toLocaleUpperCase() ?? '';
    let EmailID = this.SpecialRequestForm.get('EmailID')?.value ?? '';
    let ContactNumber = this.SpecialRequestForm.get('ContactNumber')?.value ?? '';
    if (ContactNumber != "") {
      ContactNumber = ContactNumber.internationalNumber;
    }
    let Checkindate = this.SpecialRequestForm.get('Checkindate')?.value ?? '';
    if (this.SpecialRequestForm.get('Checkintime')?.value) {
      Checkintime = this.SpecialRequestForm.get('CheckInTimeValue')?.value + " " + this.SpecialRequestForm.get('CheckInTimeType')?.value;
    }
    if (this.SpecialRequestForm.get('Checkouttime')?.value) {
      Checkouttime = this.SpecialRequestForm.get('CheckOutTimeValue')?.value + " " + this.SpecialRequestForm.get('CheckOutTimeType')?.value;
    }
    let Upgrade = this.SpecialRequestForm.get('Upgrade')?.value ?? false;
    let AdditionalComments = this.SpecialRequestForm.get('AdditionalComments')?.value ?? '';
    Checkindate = this.SetDateFormat(Checkindate);
    let data = {
      /*brandId: this.BrandId,*/
      sourceMediaId: this.CompanySettings.MetaChannel,
      //campaignId: this.BrandId,
      //marketingMediaId: this.BrandId,
      domain: environment.BaseURL + this.dynamicUrl,
      brandPhoneNumber: this.CompanySettings.EmailerPhoneNumber,
      categoryId: Type,
      bookingid: ReferenceNumber,
      comments: AdditionalComments,
      customerName: Leadguest,
      customerPhoneNumber: ContactNumber,
      email: EmailID,
      metadata: {
        checkindate: Checkindate,
        checkintime: Checkintime,
        checkouttime: Checkouttime,
        amenityName: null,
        nameAmendment: "",
        childDobAmendment: "",
        customerAcceptChanges: Upgrade.toString()
      }
    };
    this.SendMail(data);
  }
  CancellationFormonSubmit() {
    if (this.CancellationForm.invalid) {
      if (this.CancellationForm.controls.ContactNumber.errors != null) {
        this.CancellationForm.controls['ContactNumber'].setErrors({ 'incorrect': true });
      }
      return;
    }
    this.PleaseWait = true;
    let Type = this.CancellationForm.get('Type')?.value ?? 0;
    let Leadguest = this.CancellationForm.get('Leadguest')?.value ?? '';
    let ReferenceNumber = this.CancellationForm.get('ReferenceNumber')?.value.toLocaleUpperCase() ?? '';
    let EmailID = this.CancellationForm.get('EmailID')?.value ?? '';
    let ContactNumber = this.CancellationForm.get('ContactNumber')?.value ?? '';
    if (ContactNumber != "") {
      ContactNumber = ContactNumber.internationalNumber;
    }
    let Checkindate = this.CancellationForm.get('Checkindate')?.value ?? '';
    let AdditionalComments = this.CancellationForm.get('AdditionalComments')?.value ?? '';
    Checkindate = this.SetDateFormat(Checkindate);
    let data = {
      /*   brandId: this.BrandId,*/
      sourceMediaId: this.CompanySettings.MetaChannel,
      /* campaignId: this.BrandId,*/
      /*   marketingMediaId: this.BrandId,*/
      domain: environment.BaseURL + this.dynamicUrl,
      brandPhoneNumber: this.CompanySettings.EmailerPhoneNumber,
      categoryId: Type,
      bookingid: ReferenceNumber,
      comments: AdditionalComments,
      customerName: Leadguest,
      customerPhoneNumber: ContactNumber,
      email: EmailID,
      metadata: {
        checkindate: Checkindate,
        checkintime: "",
        checkouttime: "",
        amenityName: null,
        nameAmendment: "",
        childDobAmendment: "",
        customerAcceptChanges: ""
      }
    };
    this.SendMail(data);
  }
  RefundFormonSubmit() {
    if (this.RefundForm.invalid) {
      if (this.RefundForm.controls.ContactNumber.errors != null) {
        this.RefundForm.controls['ContactNumber'].setErrors({ 'incorrect': true });
      }
      return;
    }
    this.PleaseWait = true;
    let Type = this.RefundForm.get('Type')?.value ?? 0;
    let Leadguest = this.RefundForm.get('Leadguest')?.value ?? '';
    let ReferenceNumber = this.RefundForm.get('ReferenceNumber')?.value.toLocaleUpperCase() ?? '';
    let EmailID = this.RefundForm.get('EmailID')?.value ?? '';
    let ContactNumber = this.RefundForm.get('ContactNumber')?.value ?? '';
    if (ContactNumber != "") {
      ContactNumber = ContactNumber.internationalNumber;
    }
    let AdditionalComments = this.RefundForm.get('AdditionalComments')?.value ?? '';
    let data = {
      /*    brandId: this.BrandId,*/
      sourceMediaId: this.CompanySettings.MetaChannel,
      //campaignId: this.BrandId,
      //marketingMediaId: this.BrandId,
      domain: environment.BaseURL + this.dynamicUrl,
      brandPhoneNumber: this.CompanySettings.EmailerPhoneNumber,
      categoryId: Type,
      bookingid: ReferenceNumber,
      comments: AdditionalComments,
      customerName: Leadguest,
      customerPhoneNumber: ContactNumber,
      email: EmailID,
      metadata: {
        checkindate: "",
        checkintime: "",
        checkouttime: "",
        amenityName: null,
        nameAmendment: "",
        childDobAmendment: "",
        customerAcceptChanges: ""
      }
    };
    this.SendMail(data);
  }
  GeneralEnquiryFormonSubmit() {
    if (this.GeneralEnquiryForm.invalid) {
      if (this.GeneralEnquiryForm.controls.ContactNumber.errors != null) {
        this.GeneralEnquiryForm.controls['ContactNumber'].setErrors({ 'incorrect': true });
      }
      return;
    }
    this.PleaseWait = true;
    let Type = this.GeneralEnquiryForm.get('Type')?.value ?? 0;
    let Leadguest = this.GeneralEnquiryForm.get('Leadguest')?.value ?? '';
    let ReferenceNumber = this.GeneralEnquiryForm.get('ReferenceNumber')?.value.toLocaleUpperCase() ?? '';
    let EmailID = this.GeneralEnquiryForm.get('EmailID')?.value ?? '';
    let ContactNumber = this.GeneralEnquiryForm.get('ContactNumber')?.value ?? '';
    if (ContactNumber != "") {
      ContactNumber = ContactNumber.internationalNumber;
    }
    let Checkindate = this.GeneralEnquiryForm.get('Checkindate')?.value ?? '';
    let AmenityNameType = this.GeneralEnquiryForm.get('AmenityNameType')?.value ?? null;
    let amenityarray = [];
    if (AmenityNameType)
      amenityarray.push(AmenityNameType);
    let AdditionalComments = this.GeneralEnquiryForm.get('AdditionalComments')?.value ?? '';

    Checkindate = this.SetDateFormat(Checkindate);
    let data = {
      /*   brandId: this.BrandId,*/
      sourceMediaId: this.CompanySettings.MetaChannel,
      //campaignId: this.BrandId,
      //marketingMediaId: this.BrandId,
      domain: environment.BaseURL + this.dynamicUrl,
      brandPhoneNumber: this.CompanySettings.EmailerPhoneNumber,
      categoryId: Type,
      bookingid: ReferenceNumber,
      comments: AdditionalComments,
      customerName: Leadguest,
      customerPhoneNumber: ContactNumber,
      email: EmailID,
      metadata: {
        checkindate: Checkindate,
        checkintime: "",
        checkouttime: "",
        amenityName: (AmenityNameType ? amenityarray : null),
        nameAmendment: "",
        childDobAmendment: "",
        customerAcceptChanges: ""
      }
    };
    this.SendMail(data);
  }
  FeedbackandComplaintsFormonSubmit() {
    if (this.FeedbackandComplaintsForm.invalid) {
      if (this.FeedbackandComplaintsForm.controls.ContactNumber.errors != null) {
        this.FeedbackandComplaintsForm.controls['ContactNumber'].setErrors({ 'incorrect': true });
      }
      return;
    }
    this.PleaseWait = true;
    let Type = this.FeedbackandComplaintsForm.get('Type')?.value ?? 0;
    let Leadguest = this.FeedbackandComplaintsForm.get('Leadguest')?.value ?? '';
    let ReferenceNumber = this.FeedbackandComplaintsForm.get('ReferenceNumber')?.value.toLocaleUpperCase() ?? '';
    let EmailID = this.FeedbackandComplaintsForm.get('EmailID')?.value ?? '';
    let ContactNumber = this.FeedbackandComplaintsForm.get('ContactNumber')?.value ?? '';
    if (ContactNumber != "") {
      ContactNumber = ContactNumber.internationalNumber;
    }
    let Checkindate = this.FeedbackandComplaintsForm.get('Checkindate')?.value ?? '';
    let AmenityInclusionName = this.FeedbackandComplaintsForm.get('AmenityInclusionName')?.value ?? null;
    let amenityarray = [];
    if (AmenityInclusionName)
      amenityarray.push(AmenityInclusionName);
    let AdditionalComments = this.FeedbackandComplaintsForm.get('AdditionalComments')?.value ?? '';

    Checkindate = this.SetDateFormat(Checkindate);
    let data = {
      /*  brandId: this.BrandId,*/
      sourceMediaId: this.CompanySettings.MetaChannel,
      //campaignId: this.BrandId,
      //marketingMediaId: this.BrandId,
      domain: environment.BaseURL + this.dynamicUrl,
      brandPhoneNumber: this.CompanySettings.EmailerPhoneNumber,
      categoryId: Type,
      bookingid: ReferenceNumber,
      comments: AdditionalComments,
      customerName: Leadguest,
      customerPhoneNumber: ContactNumber,
      email: EmailID,
      metadata: {
        checkindate: Checkindate,
        checkintime: "",
        checkouttime: "",
        amenityName: (AmenityInclusionName ? amenityarray : null),
        nameAmendment: "",
        childDobAmendment: "",
        customerAcceptChanges: ""
      }
    };
    this.SendMail(data);
  }
  SendMail(data: any) {
    this.commonService.CustomerSupportSendMail(data).subscribe((response: any) => {
      if (response?.data) {
        if (response.data.customer && response.data.agent) {
          this.ReferenceNo = data.bookingid;
          this.Ispopup = true;
          this.PleaseWait = false;
        } else {
          this.PleaseWait = false;
          this.ReferenceNo = data.bookingid;
          this.Ispopup = true;
          this.toastr.info(response.status.message);
        }
      }
      else {
        this.toastr.error("Something went wrong. contact administrator");
        this.PleaseWait = false;
      }
    }, error => {
      this.toastr.error(error);
      this.PleaseWait = false;
    });
  }
  SetDateFormat(date: any) {
    if (date) {
      var _dt = new Date(date);
      return _dt.getFullYear() + "-" + (_dt.getMonth() + 1 < 10 ? "0" + (_dt.getMonth() + 1) : (_dt.getMonth() + 1)) + "-" + (_dt.getDate() < 10 ? "0" + _dt.getDate() : _dt.getDate());
    }
    else
      return date;

  }
  RaiseAnotherTicket() {
    window.location.reload();
  }
}
