import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HotelSearchModel, Master } from '../Model/hotel-search-model';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  header = new BehaviorSubject('M');
  footer = new BehaviorSubject('');
  FilterShowHide = new BehaviorSubject(false);
  HomeSearchBarMobile = new BehaviorSubject(false);
  RemoveAllFilter = new BehaviorSubject('');
  SearchPaxDiv = new BehaviorSubject(false);
  MobileBackclick = new BehaviorSubject('');
  SetBrand = new BehaviorSubject('');
  setHeader(header: string) {
    this.header.next(header);
  }
  setBrand(SetBrand: string) {
    this.SetBrand.next(SetBrand);
  }

  setFooter(footer: string) {
    this.footer.next(footer);
  }

  setFilterShowHide(value: boolean) {
    this.FilterShowHide.next(value);
  }
  setHomeSearchBarMobile(value: boolean) {
    this.HomeSearchBarMobile.next(value);
  }
  SetRemoveAllFilter(RemoveAllFilter: string) {
    this.RemoveAllFilter.next(RemoveAllFilter);
  }
  SetSearchPaxDiv(RemoveAllFilter: boolean) {
    this.SearchPaxDiv.next(RemoveAllFilter);
  }
  SetMobileBackclick(val: string) {
    this.MobileBackclick.next(val);
  }

  AllHotels = new BehaviorSubject<any>(null);
  SetAllHotels(data: any) {
    this.AllHotels.next(data);
  }

  totalNights = new BehaviorSubject<any>(null);
  SetTotalNights(data: any) {
    this.totalNights.next(data);
  }

}
