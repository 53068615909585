import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CommonService {
  private BaseURL = '';
  private CustomerSupportSendMailURL = 'customer-support/sendmail';
  private SubscribeSendMailURL = 'subscribe/usersubscriptions';
  private GetCategoriesURL = 'customer-support/categories';
  private GetYearsURL = 'common-service/years';
  private GetMonthsURL = 'common-service/months';
  private GetCountriesURL = 'common-service/countries';
  private GetStateURL = 'common-service/states';
  private bincodeApiURL = 'https://api.bincodes.com/cc/?&format=json&api_key=2b294d2ecfab1f55804b95d4e7a8a7ee&cc='
  private FromMOSProjectURL = 'common-service/is-mos-project';
    IsMOSFilter: any;

  constructor(private _http: HttpClient) {
    this.BaseURL = environment.BaseURL + 'api/';
  }
  private header = new HttpHeaders({
    'Accept': 'application/json',
    'Accept-Encoding': 'gzip, deflate'
  });

  SubscribeSendMail(email: string, languageCode: string, countryCode: string) {
    return this._http.get(this.BaseURL + this.SubscribeSendMailURL + '?Email=' + email + '&languageCode=' + languageCode + '&countryCode=' + countryCode, { 'headers': this.header }).pipe(catchError(this.handleError));
  }
  CustomerSupportSendMail(data: any) {
    return this._http.post(this.BaseURL + this.CustomerSupportSendMailURL, data, { 'headers': this.header }).pipe(catchError(this.handleError));
  }
  GetCategories(languageCode: string, countryCode: string) {
    return this._http.get(this.BaseURL + this.GetCategoriesURL + '?languageCode=' + languageCode + '&countryCode=' + countryCode, { 'headers': this.header }).pipe(catchError(this.handleError));
  }
  GetYears() {
    return this._http.get(this.BaseURL + this.GetYearsURL, { 'headers': this.header }).pipe(catchError(this.handleError));
  }
  GetMonths() {
    return this._http.get(this.BaseURL + this.GetMonthsURL, { 'headers': this.header }).pipe(catchError(this.handleError));
  }
  GetCountries() {
    return this._http.get(this.BaseURL + this.GetCountriesURL, { 'headers': this.header }).pipe(catchError(this.handleError));
  }
  GetStates() {
    return this._http.get(this.BaseURL + this.GetStateURL, { 'headers': this.header }).pipe(catchError(this.handleError));
  }
  CardValidate(cardNumber : any ) {
    return this._http.get(this.bincodeApiURL + cardNumber, { 'headers': this.header }).pipe(catchError(this.handleError));
  }
  isFromMOSProject() {
    return this._http.get(this.BaseURL + this.FromMOSProjectURL, { 'headers': this.header }).pipe(catchError(this.handleError));
  }
   FromMOSProject() {
    this.isFromMOSProject().subscribe((response: any) => {
      this.IsMOSFilter = response;
      environment.IsFromMosSelling = this.IsMOSFilter;
    });
  }
  isFromMOSSellingProject():boolean {
    return environment.IsFromMosSelling;
}
  private handleError(error: any) {
    let errorMessage;
    if (error.error instanceof ErrorEvent) {
      // Client-side errors       
      errorMessage = `Error: ${error.error.message}`;
    }
    else {       // Server-side errors     
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
