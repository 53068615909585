<section id="content_wrap" class="nbf_tpl_pagesection_vertical_norwd content_wrap contentwidth">
  <div class="maincnt">
    <div class="terms-wrapper">
      <div class="terms-top">
        <div class="terms-center lf-bg">
          <div class="terms-title">Privacy Policy</div>
          <div class="trms-notice">PLEASE READ BEFORE YOU BOOK</div>
        </div>
      </div>

      <div class="terms-second">
        <div class="terms-center">
          <div class="terms-intro">Privacy Policy</div>
          <div class="term-para">
            <p>This website is operated by Morerooms (“we”, “us” or “Morerooms”) and we are a controller for the purposes of the Data Protection Act 2018 and the EU General Data Protection Regulation 2016/679. This means that we are responsible for, and control the processing of, the personal information you provide to us when using this website in accordance with this privacy policy. </p>
            <p>We understand that customers care about the use and storage of their personal information and we value your trust in allowing us to do this in a careful and sensible manner. We have created this privacy policy statement in order to demonstrate our commitment to the privacy of our customers.</p>
            <p>By using Morerooms, our websites (including our mobile site), our mobile app, our social media pages, our dedicated telephone reservations line, our helpdesk functions, and any other Morerooms service, you are acknowledging that we are processing your personal information and, where necessary consenting to such practices, as outlined in this statement.</p>
            <p>If you are using our website or our recruitment portal to apply for vacancies, such activities are governed by a separate privacy policy that outlines how we process your personal information for these purposes and your rights in relation to the same. Personal Information which we collect.</p>
          </div>
        </div>
      </div>

      <div class="terms-accordion">
        <div class="terms-center">
          <div class="acc-mainn">
            <div class="acc-list act-shd">
              <div class="all-atitle" (click)="tabToggle(1)">
                We collect your information or any information that you provide on someone else, when:
              </div>
              <div class="acc-cont" *ngIf="showTab == 1 && activeStatus == 1">
                <ol>
                  <li>Make a booking for yourself or for someone</li>
                  <li>Register at our website or log in to use our services</li>
                  <li>Request any information</li>
                  <li>Sign up for the newsletter or other information</li>
                  <li>Contact us for getting our customer services by either calling or emailing us</li>
                  <li>Post something on our website or social media accounts</li>
                  <li>Take any surveys or fill out a feedback form</li>
                  <li>Raise any sort of complaint or talk to our representative</li>
                </ol>
                <div>All the information that we collect when you perform the above-mentioned tasks are as follows:</div>
              </div>
            </div>
            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(2)">
                All the information that we collect when you perform the above-mentioned tasks are as follows:
              </div>
              <div class="acc-cont" *ngIf="showTab == 2 && activeStatus == 2">
                <ol>
                  <li>Full name</li>
                  <li>Postal Address</li>
                  <li>Email Address</li>
                  <li>Telephone number</li>
                  <li>Payment details</li>
                  <li>Geolocation</li>
                  <li>Machine identifiers</li>
                  <li>Disability or supplementary information</li>
                  <li>Website behavioral data</li>
                  <li>Voice recordings and image data</li>
                  <li>Identification information</li>
                </ol>
              </div>
            </div>
            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(3)">Personal information provided by third parties                </div>
              <div class="acc-cont" *ngIf="showTab == 3 && activeStatus == 3">
                <div class="bwm">We may gather your information from other third parties to collate with the information that we have in store about you. This is to make sure that we provide you with our products and services that are relevant to your requirements. We can also get your information from social media sites where you have interacted in terms of a like, comment, or feedback.  </div>

              </div>
            </div>
            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(4)">How we use your information                </div>
              <div class="acc-cont" *ngIf="showTab == 4 && activeStatus == 4">
                <div class="bwm">We use your information or personal data in the following ways:                </div>
                <ol>
                  <li>Reservations: To complete your bookings online, which include sending you important emails or notifications regarding your reservation  </li>
                  <li>Customer service: When you communicate with us regarding us any query, it helps us to resolve your problem quickly or answer your questions with the help of your information</li>
                  <li>Marketing activities: Use your information to send you any important news that you have opted for. You can unsubscribe at any point in time if you do not want to receive such updates. Apart from this, you can also see some specific ads that are shown to you based on the data collected when you browse our website</li>
                  <li>Communication: The contact information you provide will decide that how we will communicate with you in times of need. We can contact you for different reasons like communicating for your booking, an unfinished booking, customer feedback, contact information, administrative message, and security alerts.</li>
                  <li>Research Purposes: We might invite you to partake in research activities to make our services better.</li>
                  <li>Improving our services: We might use your data to make our website and products better. We diligently work toward providing you with better services by constantly upgrading our services.</li>

                </ol>
              </div>
            </div>

            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(5)">Marketing and Communications   </div>
              <div class="acc-cont" *ngIf="showTab == 5 && activeStatus == 5">
                <div class="bwm">
                  WWe may send you promotional activities and materials about our new product or any special offer. We will use the email address
                  or phone number provided by you to us. Once you made a booking with us with particular interests, we presume that similar products will interest
                  you and we send promotional content for the same. Please note that you can opt-out of these services at any point if you find them irrelevant.
                  You provide us consent to receive such marketing offers and communications. We will offer you to tick a check-box that gives us the permission
                  to offer you such information.
                </div>
                <div class="bwm">   We may use your information from time to time for research purposes and to know customer reviews and experience about the booking they made with us. You may have unsubscribed from receiving any marketing information but the communications of this kind are separate and you might receive them from time to time.            </div>
              </div>
            </div>

            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(6)">Country-specific provisions       </div>
              <div class="acc-cont" *ngIf="showTab == 6 && activeStatus == 6">
                <div class="term-para">
                  <p>
                    Depending on the law that applies to you, we may be required to provide some additional information.
                    Please review the list below to find any additional information relevant to your situation.
                  </p>
                  <p>For California Residents – California Law</p>
                  <p>
                    This section supplements our Privacy Statement and only applies if you reside in the state of California. Where applicable,
                    it describes how we use and process your personal information (the term used under the law) and explains your particular rights under California law.
                  </p>
                </div>

              </div>
            </div>

            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(7)">We describe below the personal information we collect about you, including by identifying specific categories of information:   </div>
              <div class="acc-cont" *ngIf="showTab == 7 && activeStatus == 7">
                <div class="sml-lists">
                  <ul>
                    <li>Identifiers (e.g. your name, account number, email address, IP address)</li>
                    <li>Financial, medical, or health insurance information (e.g. your bank account number, payment card number, medical information if provided by you or on your behalf)</li>
                    <li>Characteristics of protected classifications under California or federal law (e.g. your gender, religion, sexual orientation)</li>
                    <li>Commercial information (e.g. your purchase information)</li>
                    <li>Internet or other electronic network activity information (e.g. information about your website or app usage)</li>
                    <li>Geolocation data (e.g. your physical location)</li>
                    <li>Visual information (e.g. any photographs you upload on your account)</li>
                    <li>Inferences (e.g. analytics and preferences)</li>
                    <li>Professional or employment-related information (e.g. employer and business travel details)</li>
                  </ul>
                </div>
                <div class="term-para">
                  <p>
                    We may share certain pieces of your personal information with third parties, which under California law can be treated as a 'sale' of information.
                    This may include information related to Identifiers, Commercial information, Geolocation data, Internet activity, and Inferences, as described above. We may also share your personal information, listed above under A-I for “business purposes,” such as to service providers who assist us with securing our services,
                    for payment purposes, customer support services, delivering marketing messages, or advertisements.
                  </p>
                  <p>
                    Californian law provides you with certain rights, including the right to access specific pieces of personal information,
                    learn about how we process personal information, including disclose or sell personal information, request deletion of personal information,
                    opt-out of 'sales' and not be denied goods or services for exercising these rights.
                  </p>
                  <p>
                    If you are an authorized agent wishing to exercise rights on behalf of a California consumer,
                    please contact us at the email above, attaching a copy of the consumer’s written authorization, designating you as their agent.
                    We may need to verify your identity before completing your rights request by, for example, requesting information about your previous
                    Trip Reservations with us.
                  </p>
                  <p>Morerooms services are not directed at children under the age of 16 years. Therefore, we do not knowingly sell the personal information of minors under the age of 16 years without appropriate consent, as required under the California Consumer Privacy Act (CCPA).</p>
                </div>

              </div>
            </div>

            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(8)">Consent    </div>
              <div class="acc-cont" *ngIf="showTab == 8 && activeStatus == 8">
                <div class="term-para">
                  <p>
                    When we need consent for getting some information then we will ask you for providing us with your approval.
                    You can use a tick box that is displayed on the required page to give your consent. By giving your consent you allow us to use your
                    data for different purposes and periods, visible to a different set of people as per our requirements and guidelines.
                  </p>
                </div>

              </div>
            </div>
            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(9)">Sharing your information           </div>
              <div class="acc-cont" *ngIf="showTab == 9 && activeStatus == 9">
                <div class="term-para">
                  <p>
                    We understand your concern about the personal information we have, collected in different ways. We do not share this information with anyone unless we have the
                    right to do so. We do not sell or share your information with third-party for their marketing purposes. We may share your information to understand different
                    aspects like sales, traffic, and site information to get overall customer satisfaction.
                  </p>
                </div>

              </div>
            </div>

            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(10)">In certain cases, we might share your information with third-party vendors and this includes:     </div>
              <div class="acc-cont" *ngIf="showTab == 10 && activeStatus == 10">
                <ol>
                  <li>
                    Trip provider: We will send your information to the third-party vendor or your trip provider. In case you have any questions or need any
                    alterations made to your booking, we will contact the trip provider on your behalf.
                  </li>
                  <li>Customer service agents: Your details might be shared with the customer service department.</li>
                  <li>Third-party service providers: We might share your information with third-party service providers who help us in making your booking and provide a seamless workflow. These include marketing, research, customer support, payments, or advertising partners.</li>
                  <li>
                    Competent authorities: We disclose personal data to law enforcement to the extent that it is required by law or is strictly necessary for the prevention, detection, or prosecution of criminal acts and fraud, or if we are otherwise legally obliged to do so.
                    We do not provide services to minors and thus don't store their data unless it is provided by a parent or guardian.
                    The information that we gather is solely for the purpose of getting your bookings made that too with proper consent.
                  </li>

                </ol>

              </div>
            </div>

            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(11)">You have the right to control your personal data in the following ways:  </div>
              <div class="acc-cont" *ngIf="showTab == 11 && activeStatus == 11">
                <ol>
                  <li>You can ask for a copy of your personal details.</li>
                  <li>You can inform us in case of any change that needs to be made to your personal data or information.</li>
                  <li>You can ask us to erase any data that you deem unfit or sensitive to be used.</li>
                  <li>You can withdraw your consent for any data at any point in time.</li>

                </ol>

              </div>
            </div>
            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(12)">
                Contact Us
              </div>
              <div class="acc-cont" *ngIf="showTab == 12 && activeStatus == 12">
                <div class="term-para">
                  <p>
                    In case you have any doubts or questions regarding the terms of this privacy policy then you can contact us at:
                  </p>
                  <p>Address: (Please mention)</p>
                  <p>Email: (Please mention)</p>
                </div>

              </div>
            </div>
            <div class="acc-list">
              <div class="all-atitle" (click)="tabToggle(13)">Changes in the Policy  </div>
              <div class="acc-cont" *ngIf="showTab == 13 && activeStatus == 13">
                <div class="term-para">
                  <p>
                    We can change anything in this privacy policy at any time. Thus, you should keep checking this page from time to time so that you are aware of the recent changes.
                    If you are using our website after any update then you automatically agree with the changed terms of the privacy policy.
                  </p>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  </section>
