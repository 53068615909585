
<head>

  <title>Terms and condition</title>

</head>
<body>

  <div class="terms-wrapper">
    <div class="terms-top">
      <div class="terms-center lf-bg">
        <div class="terms-title">Terms And Conditions</div>
        <div class="trms-notice">PLEASE READ BEFORE YOU BOOK</div>
      </div>
    </div>

    <div class="terms-second">
      <div class="terms-center">
        <div class="terms-intro">Introduction</div>
        <div class="term-para">
          <p>
            Welcome to Crystal Travel LLC (“Company”, “we”, “our”, “us”) ! These Terms of Service (“Terms”, “Terms of Service”) govern your use of our website located at  <a href="https://www.morerooms.com/">www.morerooms.com </a> (together
            or individually “Service”) operated by Crystal Travel LLC. Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose information that results
            from your use of our web pages.
          </p>
          <p>
            Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You acknowledge that you have read and understood Agreements, and agreeto be bound of them. If you do
            not agree with (or cannot comply with) Agreements, then you may not use the Service, but please let us know by emailing at <a href="mailto:support@morerooms.com">support@morerooms.com</a>  so we can try to find a solution. These
            Terms apply to all visitors, users and others who wish to access or use Service.
          </p>
        </div>
      </div>
    </div>

    <div class="terms-accordion">
      <div class="terms-center">
        <div class="acc-mainn">
          <div class="acc-list">
            <div class="all-atitle">
              Your Agreement with Moresand LLC DBA ‘morerooms’
            </div>
            <div class="acc-cont" >
              <div class="bwm">
                Before we make or finalize arrangements for your trip, we require that you sign below or click “I Agree” on our website. Your signature or click will signify your agreement with the
                following terms and conditions:
              </div>
              <ol>
                <li>
                  Travel Documents: You agree to review your itinerary and other travel documents for accuracy and to inform us promptly of any issues. The names on your documents must match
                  your government-issued ID.
                </li>
                <li>
                  Agent for Suppliers: Moresand LLC acts as a sales agent for any airline, hotel, car-rental company, tour operator, cruise line, or other service provider named in your itinerary or
                  confirmation (“Suppliers”). We are not responsible for the acts or omissions of the Suppliers or their failure to adhere to their own schedules, provide services or refunds, financial
                  default, or failure to honor future trip credits. We have no special knowledge regarding the financial condition of the Suppliers and no liability for recommending a trip credit in lieu of
                  a refund.
                </li>
                <li>
                  Risks of Travel and Release: We assume no responsibility for and shall not be liable for the acts or omissions on the part of any other party not under our control or any acts of God,
                  unsafe conditions, terrorism, health hazards including pandemics, illness, weather hazards, or the suitability for a disabled person of any portion of any trip. We have no special
                  knowledge of dangers during travel or at destinations. For information related to such dangers, we recommend going to the State Department travel website at www.travel.state.gov,
                  click on "Find International travel Information” then click on "Country Information", and fill in the name of the destination country. For medical and health information, we recommend
                  going to the Centers for Disease Control website at www.cdc.gov/travel, then click on “Destinations” and scroll to the name of the destination country. It is your personal decision to
                  travel, and you are doing so with full knowledge of current travel recommendations and travel restrictions with regards to the risks of COVID-19. YOU HEREBY EXPRESSLY ASSUME ALL
                  OF THESE RISKS AND DANGERS, AND YOU HEREBY EXPRESSLY AGREE TO FOREVER RELEASE, DISCHARGE AND HOLD US, AND OUR AGENTS, EMPLOYEES, OFFICERS, DIRECTORS,
                  ASSOCIATES, AFFILIATED COMPANIES, GUIDES, GROUP LEADERS, AND SUBCONTRACTORS HARMLESS AGAINST ANY AND ALL LIABILITY, ACTIONS, CAUSES OF ACTIONS, SUITS,
                  CLAIMS, AND DEMANDS OF ANY AND EVERY KIND AND NATURE WHATSOEVER WHICH YOU NOW HAVE OR WHICH MAY HEREAFTER ARISE OUT OF OR IN CONNECTION WITH THESE
                  RISKS AND DANGERS.
                </li>
                <li>
                  Foreign Entry Rules: You assume full and complete responsibility for checking and verifying any and all passport, visa, vaccination, or other entry requirements of your destination
                  and your connecting points, and all conditions regarding health, safety, security, political stability, and labor or civil unrest at such destination. Many countries require your passport
                  to be valid for six months or more after your date of entry. Some countries will not admit persons convicted of a crime. Some countries require both parents’ consent for minors to travel.
                </li>
                <li>
                  Fees and Price Drops: You agree to pay the travel planning, change, and other fees at <a href="https://www.morerooms.com/"> www.morerooms.com/fees</a>. All of our fees are non-refundable. If a Supplier drops the price of a
                  trip after booking, we will try to assist you with rebooking if the Supplier allows it, and a fee may apply. We do not guarantee any refunds or successful rebooking.
                </li>
                <li>
                  Price Increases: Travel arrangements involving airline and cruise components are subject to Suppliers’ supplemental price increases that may be imposed by the supplier and/or
                  government, even after you have completed your purchase. You hereby consent to any such price increases and authorize your credit or debit card to be used for them. Suppliers have
                  their own contracts covering cancellation penalties and other terms and conditions, and you may be bound by those contracts regardless of whether you receive notice of their terms.
                </li>
                <li>
                  Reservation Rule Violations. You agree not to purchase a ticket or tickets containing flight segments that you will not be using, such as a "point-beyond", "hidden-city", or "back-to-
                  back tickets". You further agree not to purchase a round-trip ticket that you plan to use only for one-way travel. You acknowledge that the airlines generally prohibit all such tickets,
                  and therefore we do not guarantee that the airline will honor your ticket or tickets. If you do not use one of the flights in your reservation, the airline will cancel your remaining itinerary,
                  and it may confiscate your frequent flyer points. You agree to indemnify us against airline claims for the difference between the full fare of your actual itinerary and the value of the
                  ticket or tickets that you purchased.
                </li>
                <li>
                  Insurance: For your protection, we strongly recommend that you purchase trip cancellation and travel accident insurance. Please note that, unless you buy a cancel-for-any-reason
                  policy, most policies have a specific clause stating they do not cover epidemics and pandemics, especially when travel warnings are in place. No representation or description of the
                  insurance made by our staff constitutes a binding assurance or promise about the insurance. You agree to hold us harmless for your election not to purchase travel insurance or for any
                  denial of claim by travel insurer as it relates to Covid-19 or any other claim under the policy.
                </li>
                <li>
                  Credit Card Merchant: We also strongly recommend that you use a credit card for your purchase, so that you can exercise your rights under the Fair Credit Billing Act if you do not
                  receive the services you purchased. However, if we are the credit card merchant, our role is to facilitate the sale, collect funds on your behalf, and remit those funds to the Suppliers. If
                  the Suppliers do not provide the services, your only recourse would be against the Suppliers, and you agree not to initiate a chargeback against us.
                </li>
                <li>
                  Claims Deadline and Exclusive Jurisdiction: You agree to present any claims against us within 30 days after your trip ends and to file suit within one year of the incident, and you
                  acknowledge that this expressly limits the applicable statute of limitations to one year. You agree that the courts in Jersey City, New Jersey will be the exclusive jurisdiction for all
                  claims brought by you or us, and you hereby submit to the personal jurisdiction of those courts.
                </li>


              </ol>
              <div class="bwm">
                Our Service & Post-booking fees (links to separate page)<br>
                You agree to pay the travel planning, change, and other fees listed below.
              </div>
              <div class="bwm">
                Type Applies to Amount<br>
                Booking service fee Online hotel transaction fees $ 25.00<br>
                Post-booking service fee Changes to existing reservation $ 25.00<br>
                Post-booking service fee Agent assisted cancellation $ 50.00
              </div>
              <div class="bwm">
                All our fees are non-refundable and are applied per booking. Our fees are in addition to any Airline and/or other Supplier-imposed Fees and Changes.<br>
                If a Supplier drops the price of a trip after booking, we will try to assist you with rebooking if the Supplier allows it, and a fee may apply. We do not guarantee any refunds or successful
                rebooking.
              </div>
              <div class="bwm">
                Important Note: All service fees are subject to change without notice. You will be charged the final total price as quoted, inclusive of the above booking service fees. Please review the
                total final price carefully.
              </div>
            </div>
          </div>
          <div class="acc-list">
            <div class="all-atitle" > Communications </div>
            <div class="acc-cont" >
              <div class="bwm">
              </div>  By using our Service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at support@morerooms.com.
            </div>
          </div>
          <div class="acc-list">
            <div class="all-atitle" >Purchases</div>
            <div class="acc-cont" >
              <div class="bwm"> 3. Purchases</div>
              <div class="bwn">
                If you wish to purchase any product or service made available through Service (“Purchase”), you may be asked to supply certain information relevant to your Purchase including but not limited to, your credit or debit card number, the expiration date of your card, your billing address, and your shipping information.
                <br>
                You represent and warrant that: (i) you have the legal right to use any card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.<br>
                We may employ the use of third party services for the purpose of facilitating payment and the completion of Purchases. By submitting your information, you grant us the right to provide the information to these third parties subject to our Privacy Policy.<br>
                We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons.<br>
                We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.
              </div>
            </div>
          </div>
          <div class="acc-list">
            <div class="all-atitle" >
              Contests, Sweepstakes and Promotions
            </div>
            <div class="acc-cont" >
              <div class="bwm">
                Any contests, sweepstakes or other promotions (collectively, “Promotions”) made available through Service may be governed by rules that are separate from these Terms of Service. If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms of Service, Promotion rules will apply.
              </div>
            </div>
          </div>
          <div class="acc-list">
            <div class="all-atitle" >Refunds</div>
            <div class="acc-cont" >
              <div class="bwm">We issue refunds for Contracts within 10 days of the original purchase of the Contract.</div>
            </div>
          </div>
          <div class="acc-list">
            <div class="all-atitle" >
              Content
            </div>
            <div class="acc-cont">
              <div class="bwm">
                Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material (“Content”). You are responsible for Content that you post on or through Service, including its legality, reliability, and appropriateness.<br>
                By posting Content on or through Service, You represent and warrant that: (i) Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms, and (ii) that the posting of your Content on or through Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright.<br>
                You retain any and all of your rights to any Content you submit, post or display on or through Service and you are responsible for protecting those rights. We take no responsibility and assume no liability for Content you or any third party posts on or through Service. However, by posting Content using Service you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through Service. You agree that this license includes the right for us to make your Content available to other users of Service, who may also use your Content subject to these Terms.<br>
                crystal travel llc has the right but not the obligation to monitor and edit all Content provided by users.<br>
                In addition, Content found on or through this Service are the property of crystal travel llc or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.
              </div>
            </div>
          </div>
          <div class="acc-list">
            <div class="all-atitle" >
              Prohibited Uses
            </div>
            <div class="acc-cont" >
              <div class="bwm">
                You may use Service only for lawful purposes and in accordance with Terms. You agree not to use Service:<br>
                0.1. In any way that violates any applicable national or international law or regulation.<br>
                0.2. For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.<br>
                0.3. To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.<br>
                0.4. To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.<br>
                0.5. In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.<br>
                0.6. To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Service, or which, as determined by us, may harm or offend Company or users of Service or expose them to liability.<br>
                Additionally, you agree not to:<br>
                0.1. Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with any other party’s use of Service, including their ability to engage in real time activities through Service.<br>
                0.2. Use any robot, spider, or other automatic device, process, or means to access Service for any purpose, including monitoring or copying any of the material on Service.<br>
                0.3. Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.<br>
                0.4. Use any device, software, or routine that interferes with the proper working of Service.<br>
                0.5. Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.<br>
                0.6. Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database connected to Service.<br>
                0.7. Attack Service via a denial-of-service attack or a distributed denial-of-service attack.<br>
                0.8. Take any action that may damage or falsify Company rating.<br>
                0.9. Otherwise attempt to interfere with the proper working of Service.<br>
              </div>
            </div>
          </div>
          <div class="acc-list">
            <div class="all-atitle" >
              Analytics
            </div>
            <div class="acc-cont" >
              <div class="bwm">We may use third-party Service Providers to monitor and analyze the use of our Service.</div>
            </div>
          </div>

          <div class="acc-list">
            <div class="all-atitle" >
              No Use By Minors
            </div>
            <div class="acc-cont" >
              <div class="bwm">
                Service is intended only for access and use by individuals at least eighteen (18) years old. By accessing or using Service, you warrant and represent that you are at least eighteen (18) years of age and with the full authority, right, and capacity to enter into this agreement and abide by all of the terms and conditions of Terms. If you are not at least eighteen (18) years old, you are prohibited from both the access and usage of Service.
              </div>
            </div>
          </div>

          <div class="acc-list">
            <div class="all-atitle" >
              Accounts
            </div>
            <div class="acc-cont" >
              When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on Service.<br>
              You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.<br>
              You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.<br>
              We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.
            </div>
          </div>

          <div class="acc-list">
            <div class="all-atitle" >
              Intellectual Property
            </div>
            <div class="acc-cont" >
              <div class="bwm">Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of crystal travel llc and its licensors. Service is protected by copyright, trademark, and other laws of and foreign countries. Our trademarks may not be used in connection with any product or service without the prior written consent of crystal travel llc.</div>
            </div>
          </div>

          <div class="acc-list">
            <div class="all-atitle" >
              Copyright Policy
            </div>
            <div class="acc-cont" >
              <div class="bwm">
                We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on Service infringes on the copyright or other intellectual property rights (“Infringement”) of any person or entity.<br>
                If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via email to support@morerooms.com, with the subject line: “Copyright Infringement” and include in your claim a detailed description of the alleged Infringement as detailed below, under “DMCA Notice and Procedure for Copyright Infringement Claims”<br>
                You may be held accountable for damages (including costs and attorneys’ fees) for misrepresentation or bad-faith claims on the infringement of any Content found on and/or through Service on your copyright.
              </div>
            </div>
          </div>
          <div class="acc-list">
            <div class="all-atitle" >
              DMCA Notice and Procedure for Copyright Infringement Claims
            </div>
            <div class="acc-cont" >
              <div class="bwm">
                You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):<br>
                0.1. an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright’s interest;<br>
                0.2. a description of the copyrighted work that you claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work;<br>
                0.3. identification of the URL or other specific location on Service where the material that you claim is infringing is located;<br>
                0.4. your address, telephone number, and email address;<br>
                0.5. a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;<br>
                0.6. a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.<br>
                You can contact our Copyright Agent via email at <a href="mailto:support@morerooms.com">support@morerooms.com</a>.

              </div>
            </div>
          </div>

          <div class="acc-list">
            <div class="all-atitle" >
              Error Reporting and Feedback
            </div>
            <div class="acc-cont">
              <div class="bwm">
                You may provide us either directly at support@morerooms.com or via third party sites and tools with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service (“Feedback”). You acknowledge and agree that: (i) you shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the Feedback; (ii) Company may have development ideas similar to the Feedback; (iii) Feedback does not contain confidential information or proprietary information from you or any third party; and (iv) Company is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) Feedback in any manner and for any purpose.
              </div>
            </div>
          </div>

          <div class="acc-list">
            <div class="all-atitle" >
              Links To Other Web Sites
            </div>
            <div class="acc-cont" >
              <div class="bwm">
                Our Service may contain links to third party web sites or services that are not owned or controlled by crystal travel llc. Crystal travel llc has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites. For example, the outlined Terms of Use have been created using PolicyMaker.io, a free web application for generating high-quality legal documents. Policy Maker’s Terms and Conditions generator is an easy-to-use free tool for creating an excellent standard Terms of Service template for a website, blog, e-commerce store or app. YOU ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES. WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.
              </div>
            </div>
          </div>

          <div class="acc-list">
            <div class="all-atitle" >
              Disclaimer Of Warranty
            </div>
            <div class="acc-cont" >
              <div class="bwm">
                THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK. NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              </div>
            </div>
          </div>

          <div class="acc-list">
            <div class="all-atitle" >
              Limitation Of Liability
            </div>
            <div class="acc-cont" >
              <div class="bwm">
                EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS’ FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
              </div>
            </div>
          </div>
          <div class="acc-list">
            <div class="all-atitle" >
              Termination
            </div>
            <div class="acc-cont" >
              <div class="bwm">
                We may terminate or suspend your account and bar access to Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of Terms. If you wish to terminate your account, you may simply discontinue using Service. All provisions of Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
              </div>
            </div>
          </div>
          <div class="acc-list">
            <div class="all-atitle" >
              Governing Law
            </div>
            <div class="acc-cont" >
              <div class="bwm">
                These Terms shall be governed and construed in accordance with the laws of united states of America, which governing law applies to agreement without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding Service.
              </div>
            </div>
          </div>
          <div class="acc-list">
            <div class="all-atitle" >
              Changes To Service
            </div>
            <div class="acc-cont" >
              <div class="bwm">
                We reserve the right to withdraw or amend our Service, and any service or material we provide via Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of Service, or the entire Service, to users, including registered users.
              </div>
            </div>
          </div>
          <div class="acc-list">
            <div class="all-atitle" >
              Amendments To Terms
            </div>
            <div class="acc-cont" >
              <div class="bwm">
                We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms periodically. Your continued use of the Platform following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you. By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use Service.
              </div>
            </div>
          </div>
          <div class="acc-list">
            <div class="all-atitle">
              Waiver And Severability
            </div>
            <div class="acc-cont" >
              <div class="bwm">
                No waiver by Company of any term or condition set forth in Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Company to assert a right or provision under Terms shall not constitute a waiver of such right or provision. If any provision of Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of Terms will continue in full force and effect.
              </div>
            </div>
          </div>
          <div class="acc-list">
            <div class="all-atitle" >
              Acknowledgement
            </div>
            <div class="acc-cont" >
              <div class="bwm">
                BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.
              </div>
            </div>
          </div>
          <div class="acc-list">
            <div class="all-atitle" >
              Contact Us
            </div>
            <div class="acc-cont" >
              <div class="bwm">
                Please send your feedback, comments, requests for technical support from  <a style="color: #0d6efd" href="https://customer-support.morerooms.com/"> customer-support.morerooms.com</a>.
              </div>
            </div>
          </div>
          <div class="acc-list">
            <div class="all-atitle">
              Supplier Terms & conditions
            </div>
            <div class="acc-cont" >
              <div class="bwm">
                <p>
                  Contracts for hotel and other accommodation booked through our site shall be with the relevant Travel Supplier and subject to their terms and conditions.
                </p>
                <p>
                  <a style="color: #0d6efd" href="https://developer.expediapartnersolutions.com/terms/en/">Expedia TnCs</a>.

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>




</body>

