<!-- header start -->
<div class="inner-header" *ngIf="!IsMOSFilter">
  <div class="logo-inner" (click)="RedirectToHomePage()">
    <a href="javascript:void(0)">
      <img src="assets/images/logo1.svg" alt="logo" width="192" height="42">
    </a>
  </div>
  <div class="header-right"  >
    <div class="cus-supp" routerLink="/customer-support">
      <span><img src="assets/images/customer-support-icon.svg" alt="customer support" width="19" height="23"></span>
      <p>Customer Support</p>
    </div>

    <div class="call-us">
      <a href={{callTel}}> <img src="assets/images/phone-call.png" alt="phone" width="29" height="29"></a>
      <div class="callus-inner">
        <p>Book online or call 9AM–6PM </p>
        <h4> {{callText}}</h4>
      </div>
    </div>

  </div>


</div>
<div class="mos-selling-tool-head" *ngIf="IsMOSFilter">
  
    <div class="mos-selling-header">
      <a href="javascript:void(0)"><img src="assets/images/mos-selling-logoNew.png" alt="MOS Hotel Selling"  (click)="RedirectToHomePage()"></a>
      <div class="ms-brand">
        <div class="brand-icon" *ngIf="agentBrandImage!=''">
          <img src='{{agentBrandImage}}' alt="{{agentBrandName}}">
        </div>
        <span *ngIf="agentBrandImage!=''">{{agentBrandName}}</span>
      </div>
    </div>

</div>
<!-- header ends -->
<!-- session expired popup -->
<div class="prc-change-over" *ngIf="isShown" >
  <div class="prc-change-wrap">
    <div class="close-change-hotel"  (click)="isShown=false" ><img src="assets/images/close-pchange.png" alt="close"></div>

    <div class="sess-exp">
      <div class="change-hotel0img"><img src="assets/images/home-redirect-icon.png"></div>
      <div class="sess-title">
        Are you sure, you want to redirect
        <br> to homepage?
      </div>

      <div class="stay-proceed-btn"><a (click)="isShown=false" href="javascript:void(0)">Stay here</a> <a routerLink="/">Proceed</a></div>
    </div>

  </div>
</div>
<!-- session expired end -->
