import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { any, object } from 'underscore';
import { environment } from '../../environments/environment';
import { CommonService } from '../services/common.service';
import { LocalStoreManager } from './local-store-manager.service';
@Injectable({
  providedIn: 'root'
})
export class CompanySettingsService {

  private BaseURL = '';
  private BaseURLlogin = '';
  private BaseURLloginbooking = '';
  private ConfigureURLV1 = 'Configure/GetServerConfigurationV1';
  private urlCheckSSOAuth = 'login/current-user';
  CompanySettingsService: any;
  private FromMOSProjectURL = 'common-service/is-mos-project';

  constructor(private _http: HttpClient, private localStoreManager: LocalStoreManager) {
    this.BaseURL = environment.BaseURL + 'api/';
    this.BaseURLlogin = environment.BaseURL + 'login/';
    this.BaseURLloginbooking = environment.BaseURL + 'login/?returnUrl=/en-us/hotel?';
  }

  private header = new HttpHeaders({
    'Accept': 'application/json',
    'Accept-Encoding': 'gzip, deflate'
  });
  
  GetConfiguration(languageCode: string, countryCode: string) {
    return this._http.get(this.BaseURL + this.ConfigureURLV1 + '?languageCode=' + languageCode + '&countryCode=' + countryCode, { 'headers': this.header });
  }
  loginCheck() {
      this.CheckSSOAuth().subscribe(responseItems => {
        var isCheckSSOAuth = responseItems as any;
        if (isCheckSSOAuth.data == undefined || isCheckSSOAuth.data == null) {
          console.log("CheckSSOAuth.data", isCheckSSOAuth.data);
          let bookingMosUrlpath = this.localStoreManager.getLocalItem('bookingMosUrlpath');
          let bookingMosUrlpathNew = this.localStoreManager.getLocalItem('bookingMosUrlpathNew');
          console.log("bookingMosUrlpathNew", bookingMosUrlpathNew);
          if (bookingMosUrlpath != "" && bookingMosUrlpath != undefined) {
            this.localStoreManager.removeLocalItem('bookingMosUrlpath');
            this.localStoreManager.removeLocalItem('bookingMosUrlpathNew');
            //let result = bookingMosUrlpathNew.replace("&", "__");
            let result = bookingMosUrlpathNew.replace(new RegExp('&', 'g'), '__')
            window.open(this.BaseURLloginbooking + result, "_self");
          }
          else
          window.open(this.BaseURLlogin, "_self");
        }
      });
  }
  CheckSSOAuth() {
    return this._http.get(environment.BaseURL + this.urlCheckSSOAuth, { 'headers': this.header });
  }
 

  BrandDetail(brandid: any) {
    let brand: any[] = [];
    if (brandid == '1')
      brand.push({ brandName: "Crystal Travel UK Offline", brandImageUrl: "assets/images/crystal-travel-mos-icon.jpg" });
    if (brandid == '2')
      brand.push({ brandName: "Love Cuba", brandImageUrl: "assets/images/lovecuba-mos-icon.jpg" });
    if (brandid == '3')
      brand.push({ brandName: "Paramount Cruises", brandImageUrl: "assets/images/paramount-mos-icon.jpg"});
    if (brandid == '4')
      brand.push({ brandName: "Travel Center", brandImageUrl: "assets/images/travel-center-uk-mos-icon.jpg" });
    if (brandid == '5')
      brand.push({ brandName: "World Airfares", brandImageUrl: "assets/images/worldair_l.jpg" });
    if (brandid == '6')
      brand.push({ brandName: "Mabuhay Travel", brandImageUrl: "assets/images/mabuhay_l.jpg" });
    if (brandid == '7')
      brand.push({ brandName: "Esperanza Travel", brandImageUrl: "assets/images/morerooms-mos-icon.jpg" });
    if (brandid == '8')
      brand.push({ brandName: "Business Class Travel", brandImageUrl: "assets/images/morerooms-mos-icon.jpg" });
    if (brandid == '9')
      brand.push({ brandName: "Crystal Travel US Offline", brandImageUrl: "assets/images/crystal-travel-mos-icon.jpg" });
    if (brandid == '10')
      brand.push({ brandName: "Net Flight Fares", brandImageUrl: "assets/images/morerooms-mos-icon.jpg" });
    if (brandid == '11')
      brand.push({ brandName: "Air Travel Guide", brandImageUrl: "assets/images/morerooms-mos-icon.jpg" });
    if (brandid == '12')
      brand.push({ brandName: "Affordable Luxury Travel", brandImageUrl: "assets/images/affordable-luxury-travel-mos-icon.jpg" });
    if (brandid == '13')
      brand.push({ brandName: "Itimaar", brandImageUrl: "assets/images/morerooms-mos-icon.jpg" });
    if (brandid == '14')
      brand.push({ brandName: "Test", brandImageUrl: "assets/images/morerooms-mos-icon.jpg" });
    if (brandid == '15')
      brand.push({ brandName: "Travel Centre US", brandImageUrl: "assets/images/travel-center-uk-mos-icon.jpg" });
    if (brandid == '16')
      brand.push({ brandName: "Morerooms UK", brandImageUrl: "assets/images/morerooms-mos-icon.jpg" });
    if (brandid == '17')
      brand.push({ brandName: "Morerooms US", brandImageUrl: "assets/images/morerooms-mos-icon.jpg" });
    if (brandid == '18')
      brand.push({ brandName: "Claridon Travel", brandImageUrl: "assets/images/travel-center-uk-mos-icon.jpg" });
    if (brandid == '19')
      brand.push({ brandName: "Crystal Corporate UK", brandImageUrl: "assets/images/crystal-travel-mos-iconn.jpg" });
    if (brandid == '20')
      brand.push({ brandName: "Crystal Travel UK Online", brandImageUrl: "assets/images/crystal-travel-mos-icon.jpg" });
    if (brandid == '21')
      brand.push({ brandName: "Crystal Travel US Online", brandImageUrl: "assets/images/crystal-travel-mos-icon.jpg" });
    if (brandid == '22')
      brand.push({ brandName: "Mundo Trip", brandImageUrl: "assets/images/morerooms-mos-icon.jpg" });
    if (brandid == '23')
      brand.push({ brandName: "Moresand Head Office", brandImageUrl: "assets/images/morerooms-mos-icon.jpg" });
    return brand;
  } 
  private handleError(error: any) {
    let errorMessage;
    if (error.error instanceof ErrorEvent) {
      // Client-side errors       
      errorMessage = `Error: ${error.error.message}`;
    }
    else {       // Server-side errors      
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
  public static Brand_ID = 0;
  public static Channel = '';
  public static MetaChannel = '';
  public static Currency = "";
  public static CurrencySymbol = '';
  public static CultureCode = '';
  public static readonly DateRangeFormat = 'MMM dd, yyyy';
  public static readonly GetDateFormat = 'dd';
  public static readonly GetMonthDateFormat = 'dd MMM';
  public static readonly DateFormat = 'MMM DD, YYYY';
  public static readonly EmailerPhoneNumber = '1866 389 9767';
  public static readonly EmailerTelNumber = '18663899767';
  public static readonly TimeFormat = 'hh:mm a';
  public static readonly YearMonthDateFormate = 'YYYY-MM-DD';
  public static readonly DateRangeFormatNew = 'EEE dd MMM';
  public static readonly YearsShort = 'YY';
  public  static  Year = 0;
}
